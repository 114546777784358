import { DateTime, parseISO } from "@utilities/dates";

export interface Comment {
    commentDate: DateTime;
    comments: string;
    userId: string;
    deliveryNumber: string;
    carNumber: string;
}

export type SerializableComment = Omit<Comment, "commentDate"> & {
    commentDate: string;
};

export function transformComment(
    serializableComment: SerializableComment
): Comment {
    return {
        ...serializableComment,
        commentDate: parseISO(serializableComment.commentDate)
    };
}

export function transformComments(
    serializableComments: SerializableComment[] | null
): Comment[] {
    if (!serializableComments) {
        return [];
    }
    return serializableComments.map((sc) => transformComment(sc));
}
