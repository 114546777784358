import { toLocaleDateString } from "@utilities";
import { DateTime } from "@utilities/dates";
import { DELIVERED, NOTIFIED, RELEASED } from "./constants";
import { MileStone, Status } from "./shipment-status-logic";

export function getFormattedETADate(date: string): string {
    return `ETA ${date}`;
}

export function getFormattedShipmentStatusWithDate(
    status: Exclude<Status, "ETA">,
    date: string
): string {
    const mapper = {
        [DELIVERED]: "Delivered",
        [NOTIFIED]: "Notified",
        [RELEASED]: "Released"
    };

    return `${mapper[status]} - ${date}`;
}

export function createMilestone(
    status: Status,
    statusDate: DateTime
): MileStone {
    return {
        status,
        formattedStatusDate: toLocaleDateString(statusDate)
    };
}
