import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { emptySearchResults, fetchSearchResultsAsync } from "@stores/Search";
import store, { DispatchType } from "@stores";
import { SerializableSearchResults } from "@services";

type RootState = ReturnType<typeof store.getState>;

interface Params {
    history?: string;
    q?: string;
    redirect?: string;
}

interface useSearchReturn {
    isLoading: boolean;
    searchResults: SerializableSearchResults;
}

export function useSearch(): useSearchReturn {
    const navigate = useNavigate();
    const {
        history: historyParameter,
        q: parameter,
        redirect: redirectParameter = "true"
    } = useSearchParams<Params>();

    const { isLoading, searchResults, searchTerm } = useSelector(
        (state: RootState) => state.search
    );
    const dispatch = useDispatch<DispatchType>();

    const isHistoricalSearch = historyParameter === "true";

    // any time the search page is active and the parameter changes
    useEffect(() => {
        async function refreshResultsAndTerm(term: string) {
            const results = await dispatch(
                fetchSearchResultsAsync({
                    parameter: term,
                    isHistorical: isHistoricalSearch
                })
            ).unwrap();
            if (
                shouldNavigateToNewRoute(results) &&
                redirectParameter === "true"
            ) {
                if (results) {
                    const {
                        deliveryNumber,
                        salesOrderNumber,
                        productShipmentDetails
                    } = results.shipments[0];

                    const modeOfTransportation =
                        productShipmentDetails.length > 0
                            ? productShipmentDetails[0].modeOfTransportation
                            : "RAIL";

                    const newRoute = getRouteForSingleShipment(
                        deliveryNumber,
                        modeOfTransportation,
                        salesOrderNumber,
                        (salesOrderNumber || "").toLocaleString() === parameter
                    );

                    navigate(newRoute, { replace: true });
                }
            }
        }

        if (parameter && parameter !== searchTerm) {
            refreshResultsAndTerm(parameter);
        }
    }, [
        dispatch,
        isHistoricalSearch,
        parameter,
        navigate,
        searchTerm,
        redirectParameter
    ]);

    return {
        isLoading,
        searchResults: searchResults || emptySearchResults
    };
}

export function getRouteForSingleShipment(
    deliveryNumber: number,
    modeOfTransportation: string,
    orderNumber: string,
    isOrderSearch: boolean
) {
    if (modeOfTransportation === "OCEAN") {
        if (isOrderSearch) {
            return `/order/${encodeURIComponent(orderNumber)}/ocean/`;
        }

        return `/order/${encodeURIComponent(
            orderNumber
        )}/ocean/${encodeURIComponent(deliveryNumber)}`;
    }
    return `/shipments/${encodeURIComponent(deliveryNumber)}`;
}

function shouldNavigateToNewRoute(
    results: SerializableSearchResults | undefined
) {
    if (
        results &&
        (results.shipments.length === 1 ||
            (results.shipments.length >= 1 &&
                results.shipments[0]?.productShipmentDetails[0]?.modeOfTransportation.toUpperCase() !==
                    "RAIL"))
    ) {
        return true;
    }
    return false;
}
