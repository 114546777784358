import React from "react";
import { DataGridRow } from "@cpchem/covalence-ui";
import { toLocaleDateString } from "@utilities";
import { ExceptionShipmentDetailRow } from "../models";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";

interface CellProps {
    value: DateTime | string | null;
}

type CellValue = CellProps["value"];

export function ExceptionDetailRow(row: DataGridRow): JSX.Element {
    const { deliveryNumber } = row.values as ExceptionShipmentDetailRow;

    const { values: cellData } = row;
    const cellKeys = Object.keys(cellData);

    return (
        <tr key={deliveryNumber}>
            {cellKeys.map((key) => {
                if (key === "carNumber") {
                    return generateTransportNumberCell(key, cellData);
                }

                return (
                    <RenderExceptionCell
                        key={key}
                        value={row.values[key] as CellValue}
                    />
                );
            })}
        </tr>
    );
}

function RenderExceptionCell({ value }: CellProps) {
    function normalizeCellValue(value: DateTime | number | string) {
        if (value instanceof DateTime) {
            return value.isValid ? toLocaleDateString(value) : "N/A";
        }

        return value;
    }

    if (!value) return <td />;

    return <td>{normalizeCellValue(value)}</td>;
}

function generateTransportNumberCell(key: string, values: unknown) {
    const { carNumber, deliveryNumber } = values as ExceptionShipmentDetailRow;
    return (
        <td key={key}>
            <Link
                to={`/shipments/${deliveryNumber}`}
                title={`Shipment details for railcar number: ${carNumber}`}
            >
                {carNumber}
            </Link>
        </td>
    );
}
