import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/pro-light-svg-icons";
import { faLocationDot } from "@fortawesome/pro-solid-svg-icons";
import { useShipmentDetails } from "../../../../../pages/shipment-details/hooks/use-shipment-details";
import { DateTime } from "luxon";
import { LoadingDisplay } from "@components/loader";
import { SnoBadge } from "@components/sno-status-badge";
import { Pill } from "@cpchem/covalence-ui";
import { Favorite, FavoriteType } from "@models/favorite";
import { FavoriteButton } from "../../favoritesButton/favoriteButton";
import { parseAddress } from "@utilities";
import "./styles.scss";

export interface ShipmentDetailsFavoriteViewProps {
    favorite: Favorite;
}

export function ShipmentDetailsFavoriteView({
    favorite
}: ShipmentDetailsFavoriteViewProps): JSX.Element {
    const { carNumber, deliveryNumber } = JSON.parse(favorite.componentProps);
    const { shipment, isShipmentDetailsLoading } =
        useShipmentDetails(deliveryNumber);

    const isNotFound =
        (shipment === null || shipment === undefined) &&
        !isShipmentDetailsLoading;

    const latestLocationText = parseAddress(shipment?.latestLocation);

    const etaText = shipment
        ? shipment.eta?.timestampUtc.toLocaleString(
              DateTime.DATETIME_MED_WITH_SECONDS
          )
        : "";

    return (
        <LoadingDisplay showLoading={isShipmentDetailsLoading}>
            <FavoriteButton
                favorite={{
                    path: favorite.path,
                    id: favorite.path,
                    componentKey: FavoriteType.ShipmentDetails,
                    componentProps: favorite.componentProps
                }}
            />
            <Link to={favorite.path}>
                <div className="shipment">
                    <div>
                        {isNotFound && (
                            <Pill
                                variant="solid"
                                text="Attention"
                                color="caution"
                            />
                        )}

                        <div className="emphasized title">{carNumber}</div>

                        {isNotFound && (
                            <div className="card-section">
                                <div>This shipment has been deleted.</div>
                                <div>
                                    You may remove this from your dashboard.
                                </div>
                            </div>
                        )}
                        {shipment && (
                            <>
                                <div className="small">
                                    {shipment.customerName}
                                </div>
                                <div className="card-section">
                                    <FontAwesomeIcon icon={faLocationDot} />
                                    <div className="emphasized">
                                        {latestLocationText}
                                    </div>
                                    <div className="small">
                                        Current Location
                                    </div>
                                    <SnoBadge status={shipment.sightCode} />
                                    <div className="small">Sight Code</div>
                                </div>

                                <div className="emphasized">{etaText}</div>
                                <div>
                                    <FontAwesomeIcon icon={faCalendarDays} />
                                    <span className="small">ETA Customer</span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Link>
        </LoadingDisplay>
    );
}
