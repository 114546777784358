import { Address, ShipmentStatus } from "@models";
import { DateTime } from "@utilities/dates";

export const SHIPMENT_STATUS_ACCESSOR = "shipmentStatus";
export const SHIPDATE_ACCESSOR = "shipDate";

export type ResultRowData = {
    icon?: JSX.Element;
    carNumber: string;
    customerName: string;
    destination: Address;
    shipToNumber: string;
    deliveryNumber: number;
    latestLocation?: Address;
    shipDate?: DateTime;
    daysInTransit: string;
    loadEmpty?: string;
    productName: string;
    purchaseOrderNumber?: string;
    shipmentStatus?: ShipmentStatus;
    etaDelayFlag?: number;
};

export type HistoricalResultRowData = Omit<
    ResultRowData,
    | "latestLocation"
    | "daysInTransit"
    | "loadEmpty"
    | "shipmentStatus"
    | "etaDelayFlag"
>;
