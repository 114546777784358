import { SerializableShipment } from "@models/shipment";
import { ShipmentDictionary } from "./Shipment";

export function updateDictionary(
    dictionary: ShipmentDictionary,
    id: string,
    value: SerializableShipment | undefined
): ShipmentDictionary {
    const newDictionary = { ...dictionary };
    newDictionary[id] = value;
    return newDictionary;
}
