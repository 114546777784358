import React from "react";
import { Badge } from "@cpchem/covalence-ui";

interface Props {
    className?: string;
    isHistorical: boolean;
    testId?: string;
}

export function HistoricalBadge({
    className,
    isHistorical,
    testId
}: Props): JSX.Element | null {
    if (isHistorical) {
        return (
            <Badge
                className={className}
                color="teal"
                testId={testId}
                text="Historical"
                variant="solid"
            />
        );
    }

    return null;
}
