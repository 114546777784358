import { AuthManager } from "@cpchem/azure-auth";
import { ApplicationInsights, Util } from "@microsoft/applicationinsights-web";
import {
    FetchOptionCredential,
    FetchOptionMode,
    FetchOptions
} from "@services/utilities";
import { FetchinterceptorService } from "./fetch-interceptor-service";

export class AppIngishtsFetchinterceptorImplementation
    implements FetchinterceptorService
{
    private readonly authService: AuthManager;
    private readonly appInsights: ApplicationInsights;
    constructor(authService: AuthManager, appInsights: ApplicationInsights) {
        this.authService = authService;
        this.appInsights = appInsights;
    }
    async getFetchOptionsAsync(
        base: string,
        scopes: string[],
        body?: string,
        method = "GET",
        mode = <FetchOptionMode>"cors",
        credentials = <FetchOptionCredential>"same-origin"
    ): Promise<FetchOptions> {
        const token = await this.authService.getApiToken(scopes);
        const headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");

        // Implment AppInsights tracking for all the Fetch API
        if (this.appInsights !== undefined) {
            const operId = this.appInsights.context.telemetryTrace.traceID;
            const spanId = Util.generateW3CId().substring(0, 16);
            const traceParent = `00-${operId}-${spanId}-00`;
            headers.append("traceparent", traceParent);
        }
        return method === "GET"
            ? {
                  method,
                  headers,
                  credentials,
                  mode
              }
            : {
                  method,
                  headers,
                  credentials,
                  mode,
                  body
              };
    }
}
