import React from "react";
import { toLocaleDateTimeString } from "@utilities";
import { Comment } from "@models";
export interface TripCommentProps {
    value: Comment;
    isLastComment: boolean;
    testId?: string;
}

export function TripComment(props: TripCommentProps): JSX.Element {
    const lastComment = props.isLastComment ? " - Last Comment" : "";
    return (
        <div className="trip-comment" data-testid={props.testId}>
            <div className="meta">
                <span className="userId">{props.value.userId}</span>
                <span className="date-info">
                    {toLocaleDateTimeString(props.value.commentDate)}
                    {lastComment}
                </span>
            </div>
            <div>{props.value.comments}</div>
        </div>
    );
}
