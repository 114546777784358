import React from "react";
import "../styles.scss";
import { SnoCard, ShipmentInfo } from "@components";

export interface ContainerCardProps {
    amount: number;
    containerNumber: string;
    material?: string;
    materialDescription?: string;
    unit?: string;
}

export function ContainerCard({
    amount,
    containerNumber,
    material,
    materialDescription,
    unit
}: ContainerCardProps): JSX.Element {
    return (
        <SnoCard
            className="container-card"
            title="Container #"
            subtitle={containerNumber}
        >
            <ShipmentInfo
                label="Amount(Weight/Container)"
                infoProps={[{ value: `${amount.toString()} ${unit}` }]}
            />
            <ShipmentInfo label="Material" infoProps={[{ value: material }]} />
            <ShipmentInfo
                label="Material Description"
                infoProps={[{ value: materialDescription }]}
            />
        </SnoCard>
    );
}
