import { DataGridRow } from "@cpchem/covalence-ui";
import { DateTime } from "@utilities/dates";
import {
    LEFT_AFTER_RIGHT,
    LEFT_BEFORE_RIGHT,
    NO_CHANGE
} from "./sort-constants";
import { SortByFunction } from "./sort-fn-type";

export function sortByDateTimeOn(key: string): SortByFunction {
    function getKeyFromRow(row: DataGridRow): DateTime | undefined {
        return row.values[key] as DateTime;
    }

    return (
        left: DataGridRow,
        right: DataGridRow,
        columnId: string, // eslint-disable-line @typescript-eslint/no-unused-vars
        isDescending: boolean // eslint-disable-line @typescript-eslint/no-unused-vars
    ) => {
        const leftDate = getKeyFromRow(left);
        const rightDate = getKeyFromRow(right);

        return compareDateTimes(leftDate, rightDate);
    };
}

export function compareDateTimes(
    leftDate: DateTime | undefined,
    rightDate: DateTime | undefined
): number {
    if (!leftDate && !rightDate) return NO_CHANGE;

    if (!leftDate) return LEFT_AFTER_RIGHT;

    if (!rightDate) return LEFT_BEFORE_RIGHT;

    return leftDate?.toMillis() - rightDate?.toMillis();
}
