import React from "react";
import { LoginScreen } from "../login-screen";
import { UnauthorizedPage } from "../unauthorized-page";
import { AuthenticationBoundary } from "@cpchem/azure-auth-react";
import { RestrictedApproach } from "./restricted";

export function AuthNBoundary(): JSX.Element {
    return (
        <AuthenticationBoundary
            showOnAuthenticated={<RestrictedApproach />}
            showOnAuthenticating={<LoginScreen />}
            showOnUnAuthenticated={<UnauthorizedPage />}
            showOnUnknown={<LoginScreen />}
        />
    );
}
