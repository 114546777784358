import {
    LogEvent,
    LogManager,
    logsAtOrAbove,
    LogLevel,
    getAppVersionFromMetaTag
} from "@cpchem/logging";
import { isInDevEnvironment } from "./in-dev-environment";
import config from "@config";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
// const values for app insight filter system
const DO_NOT_SEND = false;
const APPROVED_TO_SEND = true;

export function configureLogging(env: string): ApplicationInsights {
    LogManager.initialize({
        appVersion: getAppVersionFromMetaTag(),
        appInsights: {
            connectionString: config.logging.appInsights.connectionString,
            sinkPageViews: true,
            sinkLogsAtOrAboveLevel: LogLevel.WARN
        }
    });

    const inDevEnv = isInDevEnvironment(env);
    const environmentSpecificLogFilter = logsAtOrAbove(
        inDevEnv ? LogLevel.INFO : LogLevel.ERROR
    );

    LogManager.current().subscribeToLogs(
        consoleLogger,
        environmentSpecificLogFilter
    );

    LogManager.current().subscribeToEvents(consoleEventLogger);

    if (
        config.logging.logRocket?.appId !== "" &&
        config.logging.logRocket?.appId !== undefined
    ) {
        AddLogManagerAppInsightFilter(config.logging.logRocket.appId);
    }

    return LogManager.appInsights();
}

function consoleLogger(level: string, message: string): void {
    console.log(`${level}: ${message}`);
}

function consoleEventLogger(event: LogEvent): void {
    console.log(`Logged event: ${event.type}. Contents: ${event.contents}`);
}

function AddLogManagerAppInsightFilter(filter: string) {
    LogManager.appInsights().appInsights.addTelemetryInitializer((item) => {
        const isFilterTargetFound =
            item?.baseData?.target !== undefined
                ? item?.baseData?.target.indexOf(encodeURIComponent(filter)) >=
                  0
                : false;

        if (isFilterTargetFound) {
            return DO_NOT_SEND;
        }
        return APPROVED_TO_SEND;
    });
}
