import { Favorite } from "@models/favorite";
import { DispatchType, RootState } from "@stores";
import { getFavoritesAsync, toggleFavorite } from "@stores/Favorites";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

interface FavoritesHookReturn {
    personalFavorites: Favorite[];
    dispatchToggleFavorite: (favorite: Favorite) => void;
    isFavorite: (id: string) => boolean;
    isFavoritesLoading: boolean;
}

export function useFavorites(): FavoritesHookReturn {
    const { favorites, isFavoritesLoading } = useSelector(
        (state: RootState) => state.favorites
    );
    const dispatch = useDispatch<DispatchType>();

    useEffect(() => {
        if (!favorites) {
            dispatch(getFavoritesAsync());
        }
    }, [dispatch, favorites]);

    let personalFavorites: Favorite[] = [];
    if (favorites) {
        personalFavorites = favorites;
    }

    const isFavorite = (id: string) => {
        return !!personalFavorites.find((favorite) => favorite.id === id);
    };

    const dispatchToggleFavorite = (favorite: Favorite) => {
        dispatch(toggleFavorite(favorite));
    };

    return {
        personalFavorites,
        dispatchToggleFavorite,
        isFavorite,
        isFavoritesLoading
    };
}
