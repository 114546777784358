export const Detail_Page = {
    actual_delivery: "actual-delivery",
    actual_notification: "actual-notification",
    actual_release: "actual-release",
    clm_date_time: "clm-date-time",
    clm_row_root: "clm-row",
    comments_section: "comments-section",
    current_location: "current-location",
    current_railroad: "current-railroad",
    customer_name: "customer-name",
    delivery_number: "delivery-number",
    destination: "destination",
    detail_footer: "detail-footer",
    display_historical_button: "display-historical-button",
    historical_badge: "historical-badge",
    load_empty: "load-empty",
    lot: "lot",
    next_planned_move: "next-planned-move",
    origin: "origin",
    product_code: "product-code",
    purchase_order_number: "purchase-order-number",
    quantity: "quantity",
    railcar_details: "railcar-details",
    railcar_number: "railcar-number",
    route: "route",
    ship_to_number: "ship-to-number",
    ship_date: "ship-date",
    shipment_details: "shipment-details",
    shipment_status: "shipment_status",
    sight_code: "sight-code",
    sub_grid: "sub-grid",
    trip_comment: "trip-comment",
    trip_comments_modal: "trip-comments-modal",
    trip_details_button: "trip-details-button",
    trip_details_modal: "trip-details-modal",
    trip_details_modal_button: "trip-details-modal-button",
    active_result_row: "historical-result-row",
    favorite_button: "favorite-button",
    favorite_dashboard: "favorite-dashboard",
    comments_section_button: "comments-section-button",
    favorite_view: "favorite-view",
    home_button: "home-button"
};
