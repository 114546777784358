import { Personalization } from "@models";
import { DispatchType, RootState } from "@stores";
import {
    fetchPersonalization,
    setActivePersonalization
} from "@stores/Personalization";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

interface PersonalizationHookReturn {
    personalization: Personalization | null;
    isLoading: boolean;
    dispatchActivePersonalization: (id: string) => void;
    activePersonalizationId: string | null;
}

export function usePersonalization(): PersonalizationHookReturn {
    const { personalization, isLoading, activePersonalizationId } = useSelector(
        (state: RootState) => state.personalization
    );
    const dispatch = useDispatch<DispatchType>();
    useEffect(() => {
        if (!personalization) {
            dispatch(fetchPersonalization());
        }
    }, [dispatch, personalization]);

    const dispatchActivePersonalization = (id: string) =>
        dispatch(setActivePersonalization(id));

    return {
        isLoading,
        personalization,
        dispatchActivePersonalization,
        activePersonalizationId
    };
}
