import React from "react";

export function UnauthorizedPage(): JSX.Element {
    return (
        <>
            <p>
                Thank you for your interest in Shipment Central. At this time
                you don&apos;t have access.
            </p>
            <p>
                Please request access using Identity Manager (IDM) for the
                Shipment Central security role, &apos;CPC-ShipmentCentral&apos;,
                and we&apos;ll process the request as quickly as possible. If
                you&apos;re not familiar with IDM, please contact your
                supervisor or the IT Help Desk by using the Digital Workplace
                Portal.
            </p>
        </>
    );
}
