import React, { useEffect } from "react";
import { Application_Elements } from "../../elements";
import { AuthManager } from "@cpchem/azure-auth";
import "./styles.scss";

export function LoginScreen(): JSX.Element {
    useEffect(() => {
        const authMgr = AuthManager.getInstance();
        authMgr.loginAsync();
    }, []);
    return (
        <div
            className="login-screen"
            data-testid={Application_Elements.login_screen}
        >
            &nbsp;
        </div>
    );
}
