import React from "react";
import { App_Elements } from "../../../elements";
import "./styles.scss";

export default function LogoIcon(): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 120.2 120"
            className="cls-4"
            data-testid={App_Elements.ShipmentCentralIcon}
            aria-labelledby="title"
        >
            <title id="title">Home Icon</title>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path
                        className="cls-1"
                        d="M60.1,41.8,48.94,30.64a18.41,18.41,0,0,1,0-26,15.79,15.79,0,0,1,22.32,0,18.43,18.43,0,0,1,0,26Zm0-37.73a11.71,11.71,0,0,0-8.29,3.42,14.36,14.36,0,0,0,0,20.28l8.29,8.28,8.28-8.28a14.36,14.36,0,0,0,0-20.28A11.67,11.67,0,0,0,60.1,4.07Z"
                    />
                    <circle className="cls-1" cx="60.1" cy="17.63" r="4.91" />
                    <path
                        className="cls-1"
                        d="M5,68.86,0,66.05c1.12-2,2.35-3.92,3.66-5.77l4.65,3.28C7.11,65.26,6,67.05,5,68.86Zm110.3-.26a62.32,62.32,0,0,0-3.38-5.29l4.64-3.3c1.32,1.85,2.56,3.79,3.69,5.76ZM13.43,57.21,9.24,53.36c1.53-1.67,3.17-3.29,4.86-4.8l3.8,4.24C16.35,54.19,14.84,55.68,13.43,57.21ZM106.72,57c-1.42-1.53-2.93-3-4.49-4.39L106,48.34a66.82,66.82,0,0,1,4.89,4.78ZM23.8,48.12l-3.27-4.66c1.85-1.3,3.8-2.53,5.78-3.65l2.79,5C27.29,45.8,25.5,46.93,23.8,48.12ZM96.31,48A62.18,62.18,0,0,0,91,44.63l2.77-5c2,1.1,3.93,2.32,5.8,3.62ZM36.45,41.24,34.32,36c2.1-.85,4.27-1.6,6.46-2.24l1.59,5.47A61.78,61.78,0,0,0,36.45,41.24Zm47.17-.11c-1.93-.77-3.93-1.45-5.94-2l1.57-5.47a66.8,66.8,0,0,1,6.48,2.21Z"
                    />
                    <path
                        className="cls-2"
                        d="M58,86.57l-2.67.71a1.65,1.65,0,0,0-.7.41L39.71,102.44a1.45,1.45,0,0,0-.34.48l-.92,2.13a1.6,1.6,0,0,1-2.58.49l-.61-.62a1.59,1.59,0,0,0-1.25-.46l-3.27.28a1.54,1.54,0,0,0-1.16.67L28,107.59a1.6,1.6,0,0,0,.26,2.1l5.07,4.44a2.52,2.52,0,0,0,.42.27L41,117.46H34.35l-2.16-1.08-.26-.1-10.37-3a1.58,1.58,0,0,1-1-.82l-1.72-3.42a1.54,1.54,0,0,0-1.1-.85l-4.22-.84a1.57,1.57,0,0,1-1.21-1.14L10,97.38a1.54,1.54,0,0,1,0-1l5.4-14.11a1.71,1.71,0,0,1,.42-.61l7.91-7.14a1.58,1.58,0,0,1,2.18.06l1.28,1.27a1.58,1.58,0,0,0,2.33-.09l2.07-2.47a1.62,1.62,0,0,1,1.21-.57h4.32a1.65,1.65,0,0,1,1.26.61l1.47,1.89a1.57,1.57,0,0,0,2,.42l2.82-1.53a1.53,1.53,0,0,1,.75-.2H56.88a1.58,1.58,0,0,1,1,2.8l-2.34,2a1.6,1.6,0,0,0,0,2.41l3.13,2.76A1.58,1.58,0,0,1,58,86.57Z"
                    />
                    <path
                        className="cls-3"
                        d="M117.08,114.58l-.09.53-.41,2.35H78.37l-1-1.31a1.58,1.58,0,0,1-.32-1v-6.67a1.52,1.52,0,0,1,.36-1l5.83-7.1a1.6,1.6,0,0,1,1.22-.58h9a1.61,1.61,0,0,1,.77.2l7.67,4.19a1.63,1.63,0,0,0,1.38.07l3.75-1.57a1.58,1.58,0,0,1,.61-.12h5.89a1.58,1.58,0,0,1,1.55,1.28l1.76,9.06.21,1.05A1.56,1.56,0,0,1,117.08,114.58Z"
                    />
                    <path
                        className="cls-2"
                        d="M94.6,75.11l-2.53,2.8a1.62,1.62,0,0,0-.41.9L91,85.36a1.58,1.58,0,0,1-.84,1.24l-6.71,3.51a1.6,1.6,0,0,0-.84,1.41v4.92A1.58,1.58,0,0,0,84.2,98h7a1.6,1.6,0,0,0,1.46-1l1.62-3.77a1.57,1.57,0,0,1,1.45-1h5.08a1.52,1.52,0,0,1,.42.06l7.5,2.06a1.58,1.58,0,0,0,1.78-2.32l-5.81-9.88a1.52,1.52,0,0,0-.3-.37L96.84,75A1.59,1.59,0,0,0,94.6,75.11Z"
                    />
                    <path
                        className="cls-1"
                        d="M119.58,120H.55v-2.54a59.52,59.52,0,0,1,118.85-4.7c0,.76.09,1.49.13,2.24s0,1.64,0,2.46ZM5.7,114.92H114.43c0-.6-.06-1.19-.1-1.78A54.43,54.43,0,0,0,5.7,114.92Z"
                    />
                </g>
            </g>
        </svg>
    );
}
