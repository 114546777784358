import { SerializableExceptionShipmentDetail } from "@models/exception-shipment-detail";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    CarType,
    ExceptionShipmentType,
    ExceptionReport,
    ShipmentExceptions,
    ExceptionShipment
} from "@models/exceptions";
import {
    ServiceLocator,
    ShipmentsApi,
    ShipmentsApiServiceKey
} from "@services";

interface ExceptionsSliceState {
    availableExceptions: ExceptionReport[] | null;
    exceptionsCount: Record<ExceptionShipmentType, number> | null;
    exceptionReport: SerializableExceptionShipmentDetail[] | null;
    isLoading: boolean;
    selectedReport: ExceptionShipment;
}

interface ExceptionDetailsActionData {
    data: SerializableExceptionShipmentDetail[];
    exceptionShipmentType: ExceptionShipment;
}

interface FetchExceptionShipmentDetailsParams {
    exceptionShipmentType: ExceptionShipment;
    activePersonalizationId: string | null;
}

export const fetchExceptionShipmentDetails = createAsyncThunk(
    "Exceptions/fetchExceptionShipmentDetails",
    async (input: FetchExceptionShipmentDetailsParams) => {
        const api = ServiceLocator.get<ShipmentsApi>(ShipmentsApiServiceKey);
        const response = await api.getExceptionShipmentDetailsAsync(
            input.exceptionShipmentType,
            input.activePersonalizationId
        );
        return {
            data: response.data,
            exceptionShipmentType: input.exceptionShipmentType
        } as ExceptionDetailsActionData;
    }
);

export interface ShipmentExceptionsReportParams {
    carType: CarType;
    userId: string | null;
}

export const fetchShipmentExceptionReportAsync = createAsyncThunk(
    "Exceptions/fetchShipmentExceptionsReport",
    async (input: ShipmentExceptionsReportParams) => {
        const api = ServiceLocator.get<ShipmentsApi>(ShipmentsApiServiceKey);
        const response = await api.getShipmentExceptionsReport(
            input.carType,
            input.userId
        );
        return response.data;
    }
);

export const ExceptionsSlice = createSlice({
    name: "exceptions",
    initialState: {
        availableExceptions: null,
        exceptionsCount: null,
        exceptionReport: null,
        isLoading: false,
        selectedReport: 0
    } as ExceptionsSliceState,

    reducers: {
        setAvailableExceptions(state, action) {
            state.availableExceptions = action.payload;
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchExceptionShipmentDetails.pending, (state, action) => {
                state.isLoading = true;
                state.selectedReport = action.meta.arg.exceptionShipmentType;
            })
            .addCase(
                fetchExceptionShipmentDetails.fulfilled,
                (state, action) => {
                    state.isLoading = false;

                    state.exceptionReport = action.payload.data;
                }
            )
            .addCase(fetchShipmentExceptionReportAsync.pending, (state) => {
                state.isLoading = true;
                state.exceptionsCount = null;
            })
            .addCase(
                fetchShipmentExceptionReportAsync.fulfilled,
                (state, action) => {
                    state.isLoading = false;
                    state.exceptionsCount = action.payload
                        ? deriveExceptionCount(action.payload)
                        : null;
                }
            );
    }
});

function deriveExceptionCount(exceptions: ShipmentExceptions) {
    const exceptionsCount = <Record<ExceptionShipmentType, number>>{};

    exceptions.forEach(({ count, exceptionShipment }) => {
        exceptionsCount[exceptionShipment] = count;
    });

    return exceptionsCount;
}

export const { setAvailableExceptions } = ExceptionsSlice.actions;
export default ExceptionsSlice.reducer;
