import React from "react";
import { Link } from "react-router-dom";
import { DataGridRow } from "@cpchem/covalence-ui";
import { ResultRowData } from "../../models";
import { parseAddress, toLocaleDateString } from "@utilities";
import { Results_Page } from "../../elements";
import { shipmentStatusCell } from "./shipment-status";

export function ResultRow(row: DataGridRow): JSX.Element {
    const {
        deliveryNumber,
        carNumber,
        customerName,
        destination,
        shipToNumber,
        shipDate,
        daysInTransit,
        loadEmpty,
        productName,
        purchaseOrderNumber
    } = row.original as ResultRowData;

    const destinationText = parseAddress(destination);
    const purchaseOrderNumberCellData =
        (purchaseOrderNumber && (
            <Link
                to={`/search?q=${encodeURIComponent(purchaseOrderNumber)}`}
                title={`Shipments on the same purchase order: ${encodeURIComponent(
                    purchaseOrderNumber
                )}`}
            >
                {purchaseOrderNumber}
            </Link>
        )) ||
        null;

    return (
        <tr
            key={deliveryNumber}
            data-testid={`${Results_Page.active_result_row}-${row.index}`}
        >
            <td className="left">
                <Link
                    to={`/shipments/${deliveryNumber}`}
                    title={`Shipment details for railcar number: ${carNumber}`}
                >
                    {carNumber}
                </Link>
            </td>
            <td className="left">{deliveryNumber}</td>
            <td className="left">
                {customerName}
                <small>{destinationText}</small>
            </td>
            <td className="left">
                <Link
                    to={`/search?q=${encodeURIComponent(shipToNumber)}`}
                    title={`List of shipments for ship-to number: ${encodeURIComponent(
                        shipToNumber
                    )}`}
                >
                    {shipToNumber}
                </Link>
            </td>
            <td className="left">{purchaseOrderNumberCellData}</td>
            <td className="left">
                {shipDate ? toLocaleDateString(shipDate) : "N/A"}
            </td>
            {shipmentStatusCell(row)}
            <td className="left">
                {loadEmpty}
                <small>{daysInTransit}</small>
            </td>
            <td className="left">{productName}</td>
        </tr>
    );
}
