import { DataGrid, TextFilterInput } from "@cpchem/covalence-ui";
import { Shipment } from "@models";
import React from "react";
import { determineDaysInTransitDisplay } from "../../determine-dit-display";
import { ResultRow } from "./result-row";
import { TrainIcon } from "../train-icon";
import {
    ResultRowData,
    SHIPDATE_ACCESSOR,
    SHIPMENT_STATUS_ACCESSOR
} from "../../models";
import { sortByDateTimeOn } from "../../sort/sort-by-datetime";
import { sortByShipmentStatus } from "../../sort/sort-by-shipment-status";
import { filterByShipmentStatus } from "../../filter/filter-by-shipment-status";
import { ShipmentStatusTextFilter } from "../shipment-status-text-filter";
import { Results_Page } from "../../elements";
import { getFirstProduct } from "@utilities";

export type ResultsGridProps = {
    shipments: Shipment[];
};

const columns = [
    {
        Header: "Transport #",
        accessor: "carNumber",
        filterRenderer: TextFilterInput,
        filterLabel: "Transport #",
        id: "carNumber",
        filter: "includesValue"
    },
    {
        Header: "Delivery #",
        accessor: "deliveryNumber",
        filterRenderer: TextFilterInput,
        filterLabel: "Delivery #",
        id: "deliveryNumber",
        filter: "includesValue"
    },
    {
        Header: "Deliver to",
        accessor: "customerName",
        filterRenderer: TextFilterInput,
        filterLabel: "Customer Name",
        id: "customerName",
        filter: "includesValue"
    },
    {
        Header: "Ship to #",
        accessor: "shipToNumber",
        filterRenderer: TextFilterInput,
        filterLabel: "Ship to #",
        id: "shipToNumber",
        filter: "includesValue"
    },
    {
        Header: "PO #",
        accessor: "purchaseOrderNumber",
        filterRenderer: TextFilterInput,
        filterLabel: "PO #",
        id: "purchaseOrderNumber",
        filter: "includesValue"
    },
    {
        Header: "Ship Date",
        accessor: SHIPDATE_ACCESSOR,
        sortType: sortByDateTimeOn(SHIPDATE_ACCESSOR)
    },
    {
        Header: "Status / Curr. Location",
        accessor: SHIPMENT_STATUS_ACCESSOR,
        sortType: sortByShipmentStatus,
        filterRenderer: ShipmentStatusTextFilter,
        filterLabel: "Shipment Status",
        id: SHIPMENT_STATUS_ACCESSOR,
        filter: filterByShipmentStatus
    },
    {
        Header: "LE / DIT",
        accessor: "loadEmpty",
        filterRenderer: TextFilterInput,
        filterLabel: "Loaded/Empty",
        id: "loadEmpty",
        filter: "includesValue"
    },
    {
        Header: "Product",
        accessor: "productName",
        filterRenderer: TextFilterInput,
        filterLabel: "Product",
        id: "productName",
        filter: "includesValue"
    }
];

export function ActiveResultsGrid({
    shipments
}: ResultsGridProps): JSX.Element {
    const data = shipments.map(toGridRow);

    const gridProps = {
        data,
        columns,
        useStickyHeaders: true,
        isSortable: true,
        isFilterable: true,
        rowRenderer: ResultRow,
        showResultCount: true,
        testId: Results_Page.results_table
    };

    return (
        <div className="active-results">
            <DataGrid {...gridProps} />
        </div>
    );
}

function toGridRow(shipment: Shipment): ResultRowData {
    const {
        deliveryNumber,
        carNumber,
        customerName,
        latestLocation,
        shipDate,
        loadEmpty,
        productShipmentDetails,
        shipToNumber,
        destination,
        purchaseOrderNumber,
        shipmentStatus,
        etaDelayFlag
    } = shipment;

    const { name: productName } = getFirstProduct(productShipmentDetails);

    return {
        deliveryNumber,
        carNumber,
        customerName,
        latestLocation,
        shipDate,
        loadEmpty: loadEmpty,
        productName,
        shipToNumber,
        destination,
        icon: <TrainIcon />,
        daysInTransit: determineDaysInTransitDisplay(shipment),
        purchaseOrderNumber,
        shipmentStatus,
        etaDelayFlag
    };
}
