import {
    log,
    LogEvent,
    LogManager,
    LogLevel,
    logsAtOrAbove
} from "@cpchem/logging";
import { ApplicationInsights, Util } from "@microsoft/applicationinsights-web";
import { onlyPageViews } from "./only-page-views";
import { appVersion } from "./app-version";
const appVersionNumber = appVersion();

export function configureAppInsightsLogging(
    appInsights: ApplicationInsights | undefined
): void {
    if (!appInsights) {
        log("Cannot setup AppInsights Logging", LogLevel.ERROR);
        return;
    }

    LogManager.current().subscribeToEvents(
        pageViewEventSink(appInsights),
        onlyPageViews
    );
    LogManager.current().subscribeToLogs(
        appErrorLogSink(appInsights),
        logsAtOrAbove(LogLevel.ERROR)
    );
}

type EventSink = (event: LogEvent) => void;

function pageViewEventSink(appInsights: ApplicationInsights): EventSink {
    return (event) => {
        const pageContents = JSON.parse(event.contents);

        const telemetry = {
            pageType: pageContents.page,
            properties: {
                ...pageContents.params
            }
        };
        if (appVersionNumber !== null) {
            telemetry.properties.AppVersion = appVersionNumber;
        }
        appInsights.context.telemetryTrace.traceID = Util.generateW3CId();
        appInsights.trackPageView(telemetry);
    };
}

type LogSink = (level: string, message: string) => void;

function appErrorLogSink(appInsights: ApplicationInsights): LogSink {
    return (_, message) => {
        const telemetry = {
            message
        };
        appInsights.trackTrace(telemetry);
    };
}
