import React, { useState, useEffect } from "react";
import { RailDetails } from "./components/rail-details";
import { PageHeader, Surface } from "@components";
import { useApp } from "@hooks";
import { faTrain } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button } from "@cpchem/covalence-ui";
import { trackEvent } from "@cpchem/logging";
import { createPageViewEvent } from "@utilities/log/page-view-event";
import { HistoricalBadge } from "../shipment-details/components/historical-badge";
import { Detail_Page } from "./elements";
import {
    FOOTER_COMMENT,
    SHIPMENT_DETAILS_PAGE_TITLE,
    SHIPMENT_NOT_FOUND,
    VIEW_TRIP_DETAILS_BUTTON_TEXT
} from "./constants";
import "./styles.scss";
import { useShipmentDetails } from "./hooks/use-shipment-details";
import { Component_Elements } from "@components/elements";
import { FavoriteButton } from "../../components/favorites/components/favoritesButton/favoriteButton";
import { FavoriteType } from "../../models/favorite";
import { LoadingDisplay } from "@components/loader";

interface ShipmentDetailsProps {
    testId?: string;
}

export function ShipmentDetails(props: ShipmentDetailsProps): JSX.Element {
    const { setPageTitle } = useApp();
    const { shipment, isShipmentDetailsLoading } = useShipmentDetails();
    const [showTripDetails, setShowTripDetails] = useState(false);

    useEffect(() => {
        if (shipment) {
            setPageTitle(shipment.carNumber);
            trackEvent(
                createPageViewEvent(SHIPMENT_DETAILS_PAGE_TITLE, {
                    carNumber: shipment.carNumber
                })
            );
        } else {
            setPageTitle(SHIPMENT_DETAILS_PAGE_TITLE);
            trackEvent(createPageViewEvent(SHIPMENT_DETAILS_PAGE_TITLE));
        }
    }, [shipment, setPageTitle]);

    function onViewTripDetailsButtonClick() {
        setShowTripDetails((value) => !value);
    }
    function onCloseTripDetails() {
        setShowTripDetails(false);
    }

    const shipmentDetailTestId = props.testId || Detail_Page.shipment_details;
    const componentProps = {
        carNumber: shipment?.carNumber,
        deliveryNumber: shipment?.deliveryNumber.toString()
    };

    const isLoading = shipment === undefined && isShipmentDetailsLoading;
    const isNotFound =
        (shipment === null || shipment === undefined) &&
        !isShipmentDetailsLoading;

    return (
        <div className="shipment-details" data-testid={shipmentDetailTestId}>
            {isLoading && (
                <PageHeader
                    title={SHIPMENT_DETAILS_PAGE_TITLE}
                    icon={<FontAwesomeIcon icon={faTrain} role="none" />}
                />
            )}
            {isNotFound && (
                <Alert message={SHIPMENT_NOT_FOUND} severity="danger" />
            )}
            <LoadingDisplay showLoading={isLoading}>
                {shipment && (
                    <>
                        <PageHeader
                            badge={
                                <HistoricalBadge
                                    isHistorical={shipment?.isHistorical}
                                    testId={Detail_Page.historical_badge}
                                />
                            }
                            title={SHIPMENT_DETAILS_PAGE_TITLE}
                            icon={
                                <FontAwesomeIcon icon={faTrain} role="none" />
                            }
                        >
                            <FavoriteButton
                                favorite={{
                                    path: window.location.pathname,
                                    id: window.location.pathname,
                                    componentKey: FavoriteType.ShipmentDetails,
                                    componentProps:
                                        JSON.stringify(componentProps)
                                }}
                            />
                            <Button
                                color="primary"
                                text={VIEW_TRIP_DETAILS_BUTTON_TEXT}
                                onClick={onViewTripDetailsButtonClick}
                                // data-testid={Detail_Page.trip_details_button}
                                testId={Detail_Page.trip_details_button}
                            />
                        </PageHeader>
                        <Surface
                            className="surface-no-padding-top"
                            testId={Component_Elements.surface}
                        >
                            <RailDetails
                                showTripDetails={showTripDetails}
                                onTripDetailsModalClosed={onCloseTripDetails}
                            />
                        </Surface>
                        <div
                            className="footer-comment"
                            data-testid={Detail_Page.detail_footer}
                        >
                            {FOOTER_COMMENT}
                        </div>
                    </>
                )}
            </LoadingDisplay>
        </div>
    );
}
