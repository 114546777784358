import React, { useState } from "react";
import { Button, Container } from "@cpchem/covalence-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-light-svg-icons";

import "./styles.scss";
import { DrawerGroup } from "./components/drawer-group";

export interface DrawerLinkGroup {
    name?: string;

    links: DrawerLink[];
}

export interface DrawerLink {
    name: string;

    url: string;

    key: string;

    icon?: JSX.Element;

    title?: string;

    target?: string;

    links?: DrawerLink[];

    // isExact is a prop used in the NavLink component. It determines when to apply the active styling to the NavLink -- either loosely or strictly matched URLs
    isExact?: boolean;

    testId?: string;
}

export interface DrawerProps {
    drawerLinkGroups: DrawerLinkGroup[];
    className?: string;
    links?: DrawerLink[];
}

export function Drawer({
    drawerLinkGroups,
    className
}: DrawerProps): JSX.Element {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const toggleDrawer = () => {
        setIsCollapsed(!isCollapsed);
    };

    let cn = "drawer";
    if (className) {
        cn += ` ${className}`;
    }

    if (isCollapsed) {
        cn += " collapsed";
    }

    return (
        <div className={cn}>
            <ul>
                <li>
                    <Container className="button-container">
                        <Button
                            variant="text"
                            onClick={toggleDrawer}
                            color="primary"
                            icon={
                                <FontAwesomeIcon
                                    title="Toggle Drawer"
                                    className="fa-lg"
                                    icon={faBars}
                                />
                            }
                        />
                    </Container>
                </li>
                {drawerLinkGroups?.map((drawerGroup) => {
                    return (
                        <DrawerGroup
                            key={drawerGroup.name}
                            drawerGroup={drawerGroup}
                        />
                    );
                })}
            </ul>
        </div>
    );
}
