import { FilterRendererProps } from "@cpchem/covalence-ui";
import { SerializableShipmentStatus } from "@models";
import { DELIVERED, ETA, NOTIFIED, RELEASED } from "../constants";

export type GridFilterList = FilterRendererProps["options"];
export type ShipmentStatusOptions = ReturnType<
    typeof deriveShipmentStatusOptions
>;

export function deriveShipmentStatusOptions(
    id: string,
    options: GridFilterList
) {
    const optionList = options.map(
        (option) =>
            (option.value as unknown as SerializableShipmentStatus)?.status
    );

    const availableOptions = [...new Set(optionList)];

    return [ETA, NOTIFIED, DELIVERED, RELEASED]
        .filter((status) => availableOptions.includes(status))
        .map((option) => ({ id, value: option }));
}
