import React, { useEffect, useMemo } from "react";
import { SearchResults, transformSearchResults } from "@services";
import { Surface } from "@components";
import "./styles.scss";
import { useApp } from "@hooks";
import { SearchGuide } from "@components/search-guide";
import { Shipment } from "@models";
import { trackEvent } from "@cpchem/logging";
import { createPageViewEvent } from "@utilities/log/page-view-event";
import { useSearch } from "../../app/hooks";
import { ResultsGrid } from "./components/results-grid";
import { SearchPageHeader } from "./components/search-page-header";
import { Results_Page } from "./elements";
import { Component_Elements } from "@components/elements";
import { LoadingDisplay } from "@components/loader";

function byShipDateDescending(a: Shipment, b: Shipment): number {
    return a.shipDate && b.shipDate
        ? b.shipDate.toMillis() - a.shipDate.toMillis()
        : 0;
}

export function SearchPage(): JSX.Element {
    const { setPageTitle } = useApp();
    const { isLoading, searchResults } = useSearch();
    const results = useMemo(
        () => transformSearchResults(searchResults),
        [searchResults]
    );

    useEffect(() => {
        setPageTitle("Shipment Search");
    }, [setPageTitle]);

    useEffect(() => {
        trackEvent(
            createPageViewEvent("Search Results", {
                searchTerm: results.searchTerm,
                resultCount: results.shipments.length
            })
        );
    }, [results]);

    const { isHistorical, shipments } = results;
    const gotResults = shipments.length;
    const sortedShipments = shipments.sort(byShipDateDescending);
    return (
        <div className="search-page" data-testid={Results_Page.search_page}>
            <SearchPageHeader />
            <LoadingDisplay showLoading={isLoading}>
                <div
                    className="sticky-results"
                    data-testid={Results_Page.results}
                >
                    <Surface testId={Component_Elements.surface}>
                        {gotResults ? (
                            <ResultsGrid
                                isHistorical={isHistorical}
                                shipments={sortedShipments}
                            />
                        ) : (
                            noResults(results)
                        )}
                    </Surface>
                </div>
            </LoadingDisplay>
        </div>
    );
}

function noResults(results: SearchResults): JSX.Element {
    return (
        <>
            <div
                className="no-search-results"
                data-testid={Results_Page.no_results}
            >
                <div>No search results.</div>
                <div>
                    Your search - &quot;{results.searchTerm}&quot; did not match
                    any shipments.
                </div>
            </div>
            <SearchGuide />
        </>
    );
}
