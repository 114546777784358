import React from "react";
import "../styles.scss";
import { Link } from "react-router-dom";

export interface InfoProps {
    value: string | number | undefined;
    link?: string;
}

export interface ShipmentInfoProps {
    // maximum of 3 values
    label: string;
    infoProps?: InfoProps[];
}

export function ShipmentInfo({
    infoProps,
    label
}: ShipmentInfoProps): JSX.Element {
    return (
        <div className="shipment-info">
            <label> {label} </label>
            <div className="shipment-value">
                {infoProps &&
                    infoProps.map((info, index) => {
                        return (
                            <span key={`shipmentInfo-${index.toString()}`}>
                                {index !== 0 && <span>,</span>}
                                {info.link ? (
                                    <Link to={info.link}>{info.value}</Link>
                                ) : (
                                    <span>{info.value}</span>
                                )}
                            </span>
                        );
                    })}
            </div>
        </div>
    );
}
