import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { trackEvent } from "@cpchem/logging";
import { Grid, Select } from "@cpchem/covalence-ui";
import { createPageViewEvent } from "@utilities/log/page-view-event";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageHeader, ManagerNoPersonalization, Surface } from "@components";
import { useApp } from "../../hooks";
import { Widget } from "./components/widget";
import { EXCEPTION_PAGE_TITLE } from "./constants";
import "./styles.scss";
import {
    CarTypeParam,
    iconMapper,
    ExceptionReportWidget,
    CarType
} from "@models/exceptions";
import { useShipmentExceptions } from "./hooks/use-shipment-exceptions";
import { usePersonalization } from "./hooks/use-personalization";
import { LoadingDisplay } from "@components/loader";

interface SelectOption {
    key: string;
    isDisabled?: boolean;
    value: string;
    text: string;
}

interface SelectOptionGroup {
    key: string;
    label: string;
    isDisabled?: boolean;
    options: SelectOption[];
}

export function ShipmentExceptionsPage(): JSX.Element {
    const { setPageTitle } = useApp();
    const navigate = useNavigate();
    const {
        isLoading: isLoadingPersonalization,
        personalization,
        dispatchActivePersonalization,
        activePersonalizationId
    } = usePersonalization();
    const { isLoading, exceptions } = useShipmentExceptions(
        personalization,
        activePersonalizationId
    );
    const { carType } = useParams<keyof CarTypeParam>() as CarTypeParam;

    useEffect(() => {
        if (!Object.values(CarType).includes(carType)) {
            navigate("/");
        }
    }, [carType, navigate]);

    useEffect(() => {
        setPageTitle(EXCEPTION_PAGE_TITLE.header);
    }, [setPageTitle]);

    useEffect(() => {
        trackEvent(createPageViewEvent(EXCEPTION_PAGE_TITLE.header));
    }, [setPageTitle]);

    const pageIcon = iconMapper[carType];

    function createWidgetList(exceptions: ExceptionReportWidget[]) {
        return exceptions.map(({ count, key, icon, reportName, reportUrl }) => {
            return (
                <Widget
                    count={count}
                    icon={icon}
                    key={key}
                    name={reportName}
                    path={reportUrl}
                />
            );
        });
    }
    const widgetsClassNames = ["widgets"];
    const carTypeTitle = EXCEPTION_PAGE_TITLE[carType];

    if (exceptions) {
        exceptions.length < 3 && widgetsClassNames.push("col-2");
    }

    let options: (SelectOption | SelectOptionGroup)[] =
        personalization?.teamMembers?.map((x) => {
            return {
                key: x.id,
                value: x.id,
                text: x.fullName,
                disabled: false
            } as SelectOption;
        }) || [];

    const peersLabel = personalization?.isManager
        ? "My Direct Reports"
        : "My Peers";
    options = [
        {
            key: "0",
            value: personalization?.currentUserId
                ? personalization.currentUserId
                : "",
            text: personalization?.currentUserFullName
                ? personalization.currentUserFullName
                : ""
        },
        {
            key: "peerOrSubordinateGroup",
            label: peersLabel,
            // the original list of options only has SelectOptions in it
            options: options as SelectOption[]
        }
    ];

    const displayPersonalization =
        !isLoadingPersonalization && personalization?.hasPersonalization;

    const displayManagerNoResults =
        displayPersonalization &&
        personalization?.isManager &&
        activePersonalizationId === personalization.currentUserId;

    const displayWidgets =
        !isLoading && exceptions.length !== 0 && !displayManagerNoResults;

    return (
        <div className="shipment-exceptions" data-testid="">
            <div
                className={
                    `shipment-exceptions-header` +
                    `${
                        displayPersonalization
                            ? "-personalized"
                            : "-not-personalized"
                    }`
                }
            >
                <PageHeader title={EXCEPTION_PAGE_TITLE.header} />
                {displayPersonalization && (
                    <div className="personalization-select-div">
                        <label>Personalized for:</label>
                        <Select
                            label=""
                            options={options}
                            onChange={(value) => {
                                dispatchActivePersonalization(value);
                            }}
                            className="personalization-select"
                            value={activePersonalizationId || ""}
                            isDisabled={isLoading}
                        />
                    </div>
                )}
            </div>
            <PageHeader
                isSubheader
                icon={
                    <FontAwesomeIcon
                        className="exception-icon"
                        icon={pageIcon}
                    />
                }
                title={carTypeTitle}
            />
            <LoadingDisplay showLoading={isLoading || isLoadingPersonalization}>
                {displayManagerNoResults && <ManagerNoPersonalization />}

                {displayWidgets && (
                    <Grid className={widgetsClassNames.join(" ")}>
                        {createWidgetList(exceptions)}
                    </Grid>
                )}
                {exceptions.length === 0 && noResults(carTypeTitle)}
            </LoadingDisplay>
        </div>
    );
}

function noResults(title: string): JSX.Element {
    return (
        <Surface>
            <div>No {title} found.</div>
        </Surface>
    );
}
