import React from "react";
import "./styles.scss";
import config from "@config";
import { Component_Elements } from "@components/elements";

export function SearchGuide(): JSX.Element {
    return (
        <div
            className="search-guide-container"
            data-testid={Component_Elements.guide_container}
        >
            <div
                className="search-guide-title"
                data-testid={Component_Elements.guide_title}
            >
                Search Tips:
            </div>
            <div>
                Search shipments by Transport #, Delivery #, Ship to #, or PO #.
                Valid search formats:
            </div>
            <div
                className="search-guide-contents"
                data-testid={Component_Elements.guide_contents}
            >
                <ul>
                    <li>
                        Transport #: CPCX001234, CPCX1234, CPCX-1234, Cpcx-1234
                    </li>
                    <li>Delivery #: 0081234567, 81234567</li>
                    <li>Ship to #: 0020001234, 20001234, CPMEST, UAL8, ual8</li>
                    <li>PO #: Exact match to SAP</li>
                </ul>
            </div>
            <div>
                * Shipment Central currently supports Rail shipment search only.
                To search for Container and Truck shipments, visit{" "}
                <a
                    href={config.fourKitesSignOnUrl}
                    target="_blank"
                    rel="noreferrer"
                >
                    FourKites
                </a>
                .
            </div>
        </div>
    );
}
