import React from "react";
import { useFavorites } from "../../hooks/use-favorites";
import { Favorite } from "@models/favorite";
import { FavoritesBanner } from "../favoritesBanner";
import "./styles.scss";
import { FavoriteWrapper } from "../favoritesWrapper/favoritesWrapper";

export function FavoritesDashboard(): JSX.Element {
    const { personalFavorites } = useFavorites();

    const instantiateFavoritesList = () => {
        return personalFavorites.map((favorite: Favorite, idx: number) => {
            return (
                <div
                    className="view"
                    key={favorite.id}
                    data-testid={`favorite-view-${idx}`}
                >
                    <FavoriteWrapper favorite={favorite} />
                </div>
            );
        });
    };

    if (personalFavorites.length === 0) {
        return <FavoritesBanner />;
    }

    return (
        <div className="dashboard" data-testid="favorite-dashboard">
            {instantiateFavoritesList()}
        </div>
    );
}
