import React from "react";
import { Route, Routes } from "react-router-dom";
import { Grid } from "@cpchem/covalence-ui";
import {
    ExceptionResultsPage,
    LandingPage,
    SearchPage,
    ShipmentExceptionsPage,
    OrderDetails,
    ShipmentDetails,
    DeliveryDetails
} from "../pages";
import { AppBar } from "./components/sno-app-bar";
import { SnoDrawer } from "./components/sno-drawer";
import "./styles.scss";
import { Application_Elements } from "./elements";
import { routePath } from "./route-paths";

export function App(): JSX.Element {
    return (
        <Grid className="app" testId={Application_Elements.app}>
            <AppBar />
            <div className="sidebar">
                <SnoDrawer />
            </div>
            <div className="main">
                <Routes>
                    <Route path={routePath.LANDING} element={<LandingPage />} />
                    <Route path={routePath.SEARCH} element={<SearchPage />} />
                    <Route
                        path={routePath.SHIPMENT_DETAILS}
                        element={<ShipmentDetails />}
                    />
                    {/* This route will be removed in the routing story and merged with shipment details */}
                    <Route
                        path={routePath.OCEAN_DELIVERY_DETAILS}
                        element={<DeliveryDetails />}
                    />
                    <Route
                        path={routePath.OCEAN_ORDER_DETAILS}
                        element={<OrderDetails />}
                    />
                    <Route
                        path={routePath.SHIPMENT_EXCEPTIONS}
                        element={<ShipmentExceptionsPage />}
                    />
                    <Route
                        path={routePath.EXCEPTIONS_RESULTS}
                        element={<ExceptionResultsPage />}
                    />
                </Routes>
            </div>
        </Grid>
    );
}

export default App;
