import React from "react";
import "./styles.scss";

export interface SurfaceProps {
    className?: string;
    children: React.ReactNode;
    testId?: string;
}

export function Surface({
    children,
    className,
    testId
}: SurfaceProps): JSX.Element {
    let cn = "surface";

    if (className) {
        cn += ` ${className}`;
    }
    return (
        <div className={cn} data-testid={testId}>
            {children}
        </div>
    );
}
