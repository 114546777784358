import React, { useEffect } from "react";
import App from "../..";
import { UnauthorizedPage } from "../unauthorized-page";
import {
    Restricted,
    Unauthorized,
    Authorized,
    useAuth
} from "@cpchem/azure-auth-react";
import { setLogRocketUserId } from "@utilities/log/configure-log-rocket";

export function RestrictedApproach(): JSX.Element {
    const { currentAccount } = useAuth({});

    useEffect(() => {
        if (currentAccount) {
            setLogRocketUserId(currentAccount);
        }
    }, [currentAccount]);

    return (
        <Restricted toRoles={["SNO.UIRole"]}>
            <>
                <Authorized>
                    <App />
                </Authorized>
                <Unauthorized>
                    <UnauthorizedPage />
                </Unauthorized>
            </>
        </Restricted>
    );
}
