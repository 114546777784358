const SHIPMENT_EXCEPTIONS = `/exceptions/:carType`;

export const routePath = {
    LANDING: "/",
    SEARCH: "/search",
    SHIPMENT_DETAILS: "/shipments/:deliveryNumber",
    OCEAN_ORDER_DETAILS: "order/:orderNumber/ocean",
    OCEAN_DELIVERY_DETAILS: "order/:orderNumber/ocean/:deliveryNumber",
    SHIPMENT_EXCEPTIONS,
    EXCEPTIONS_RESULTS: `${SHIPMENT_EXCEPTIONS}/:exceptionType`
};
