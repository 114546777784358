import { Personalization } from "@models/personalization";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    ServiceLocator,
    ShipmentsApi,
    ShipmentsApiServiceKey
} from "@services";

interface PersonalizationSliceState {
    isLoading: boolean;
    personalization: Personalization | null;
    activePersonalizationId: string | null;
}

interface PersonalizationActionData {
    data: Personalization;
}

export const fetchPersonalization = createAsyncThunk(
    "Personalization/fetchPersonalization",
    async () => {
        const api = ServiceLocator.get<ShipmentsApi>(ShipmentsApiServiceKey);
        const response = await api.getPersonalizationAsync();
        return {
            data: response.data
        } as PersonalizationActionData;
    }
);

export const PersonalizationSlice = createSlice({
    name: "personalization",
    initialState: {
        isLoading: false,
        personalization: null,
        activePersonalizationId: null
    } as PersonalizationSliceState,
    reducers: {
        setActivePersonalization(state, action) {
            state.activePersonalizationId = action.payload;
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchPersonalization.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchPersonalization.fulfilled, (state, action) => {
                const personalization = action.payload.data;
                // set current user as active
                state.activePersonalizationId = personalization.currentUserId
                    ? personalization.currentUserId
                    : null;
                // Remove the current user from list of team members if they are a manager
                personalization.teamMembers =
                    personalization.teamMembers?.filter(
                        (x) => x.id !== personalization.currentUserId
                    );

                state.isLoading = false;
                state.personalization = personalization;
            });
    }
});

export const { setActivePersonalization } = PersonalizationSlice.actions;
export default PersonalizationSlice.reducer;
