import React from "react";
import {
    ContainerInformation,
    PageHeader,
    ShipmentInfo,
    SnoCard
} from "@components";
import {
    faShip,
    faReceipt,
    faRectangleVerticalHistory
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    ORDER_DETAILS_PAGE_SUB_TITLE,
    ORDER_DETAILS_PAGE_TITLE
} from "../shipment-details/constants";
import { useOrderDetails } from "./hooks/use-orderDetails";

import "./styles.scss";

export function OrderDetails(): JSX.Element {
    const { isLoading, orderDetails } = useOrderDetails();

    return (
        <div className="order-page">
            <PageHeader
                title={ORDER_DETAILS_PAGE_TITLE}
                icon={<FontAwesomeIcon icon={faShip} role="none" />}
            />
            {isLoading && "Loading..."}
            {!isLoading && orderDetails === null && "No Results"}
            {!isLoading && orderDetails && (
                <>
                    <div className="section">
                        <SnoCard
                            className="large-card"
                            icon={faReceipt}
                            large
                            title={ORDER_DETAILS_PAGE_TITLE}
                            subtitle={ORDER_DETAILS_PAGE_SUB_TITLE}
                            subIcon={faRectangleVerticalHistory}
                        >
                            <ShipmentInfo
                                label="Order #"
                                infoProps={[
                                    { value: orderDetails?.orderNumber }
                                ]}
                            />
                            <ShipmentInfo
                                label="PO #"
                                infoProps={[
                                    { value: orderDetails?.purchaseOrderNumber }
                                ]}
                            />
                            <ShipmentInfo
                                label="Payment Terms"
                                infoProps={[
                                    { value: orderDetails?.termsOfPayment }
                                ]}
                            />
                            <ShipmentInfo
                                label="Delivery"
                                infoProps={orderDetails?.deliveries?.map(
                                    (delivery) => {
                                        return {
                                            link: `/order/${orderDetails.orderNumber}/ocean/${delivery.deliveryNumber}`,
                                            value: delivery.deliveryNumber
                                        };
                                    }
                                )}
                            />
                            <ShipmentInfo
                                label="EAC"
                                infoProps={[{ value: orderDetails?.eac }]}
                            />
                            <ShipmentInfo
                                label="Credit"
                                infoProps={[
                                    { value: orderDetails?.creditStatus }
                                ]}
                            />
                            <ShipmentInfo
                                label="Origin Plant Code"
                                infoProps={[{ value: "" }]}
                            />
                        </SnoCard>
                        <SnoCard title="Status" className="small-card">
                            <>Progress Bar WIP</>
                        </SnoCard>
                    </div>
                    <ContainerInformation
                        containers={orderDetails?.deliveries
                            .map((delivery) => delivery.containers)
                            .flat()}
                    />
                </>
            )}
        </div>
    );
}
