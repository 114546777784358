import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    ServiceLocator,
    ShipmentsApi,
    ShipmentsApiServiceKey,
    SerializableSearchResults
} from "@services";

export interface FetchSearchParams {
    isHistorical: boolean;
    parameter: string;
}

export const fetchSearchResultsAsync = createAsyncThunk(
    "Search/fetchSearchResults",
    async (input: FetchSearchParams) => {
        const api = ServiceLocator.get<ShipmentsApi>(ShipmentsApiServiceKey);
        const response = await api.searchAsync(input);
        return response.data;
    }
);

export const emptySearchResults = {
    isHistorical: false,
    searchTerm: "",
    shipments: []
};

interface SearchSliceState {
    isLoading: boolean;
    searchResults: SerializableSearchResults | null;
    searchTerm: string;
}

export const SearchSlice = createSlice({
    name: "search",
    initialState: {
        isLoading: false,
        searchResults: null,
        searchTerm: ""
    } as SearchSliceState,

    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSearchResultsAsync.pending, (state, action) => {
                const { parameter } = action.meta.arg;

                state.isLoading = true;
                state.searchTerm = parameter;
            })
            .addCase(fetchSearchResultsAsync.fulfilled, (state, action) => {
                state.isLoading = false;
                state.searchResults = action.payload || emptySearchResults;
            });
    }
});

export default SearchSlice.reducer;
