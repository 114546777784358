import config from "@config";
import { ApiResponse } from "../";
import {
    FetchinterceptorService,
    FetchServiceKey
} from "@services/fetchInterceptor";
import { FetchOptions, FetchOptionMethod, ServiceLocator } from "@services";
import { PersonalizationApi } from "./personalization-api";
import { log, LogLevel } from "@cpchem/logging";
import { PersonalizationResponse } from "./models/personalization-response";

function handle401Response() {
    log(`You are not authorized to make this call.`, LogLevel.ERROR);
    return {
        data: null
    };
}

export class PersonalizationApiImplementation implements PersonalizationApi {
    private readonly base = config.api.personalization.url;
    private readonly scopes = config.api.personalization.scopes;

    private interceptor: FetchinterceptorService;

    constructor() {
        this.interceptor =
            ServiceLocator.get<FetchinterceptorService>(FetchServiceKey);
    }

    private async ensureFetchOptionsAsync(
        method: FetchOptionMethod,
        body?: string
    ): Promise<FetchOptions> {
        return this.interceptor.getFetchOptionsAsync(
            this.base,
            this.scopes,
            body,
            method
        );
    }

    async deletePersonalizationByKeyAsync(
        key: string
    ): Promise<ApiResponse<null>> {
        const uri = `${this.base}/${encodeURIComponent(key)}`;

        const options = await this.ensureFetchOptionsAsync("DELETE");

        const res = await fetch(uri, options);

        if (res.ok) {
            return {
                data: null
            };
        }

        if (res.status === 401) {
            return handle401Response();
        }

        if (res.status === 404) {
            log(`Personalization API not found.`, LogLevel.ERROR);
            return {
                data: null
            };
        }

        log(
            `Unknown error when attempting to delete personalization values for ${key}. Status: ${res.statusText}`,
            LogLevel.ERROR
        );
        return {
            error: res.statusText
        };
    }

    async getAllPersonalizationAsync(): Promise<
        ApiResponse<PersonalizationResponse[] | null>
    > {
        const uri = `${this.base}/`;

        const options = await this.ensureFetchOptionsAsync("GET");

        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json
            };
        }

        if (res.status === 401) {
            return handle401Response();
        }

        if (res.status === 404) {
            log(
                `Data not found for this combination of Application ID and User Principal Name.`,
                LogLevel.ERROR
            );
            return {
                data: null
            };
        }

        log(
            `Unknown error when attempting to retrieve all personalization values for this combination of Application ID and User Principal Name. Status: ${res.statusText}`,
            LogLevel.ERROR
        );
        return {
            error: res.statusText
        };
    }

    async getPersonalizationByKeyAsync(
        key: string
    ): Promise<ApiResponse<PersonalizationResponse | null>> {
        const uri = `${this.base}?key=${encodeURIComponent(key)}`;

        const options = await this.ensureFetchOptionsAsync("GET");

        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json.length === 0 ? null : json[0]
            };
        }

        if (res.status === 401) {
            return handle401Response();
        }

        if (res.status === 404) {
            log(
                `Data not found for this combination of Application ID, User Principal Name, and ${key}`,
                LogLevel.ERROR
            );
            return {
                data: null
            };
        }

        log(
            `Unknown error when attempting to retrieve personalization values for this combination of Application ID, User Principal Name, and ${key}. Status: ${res.statusText}`,
            LogLevel.ERROR
        );
        return {
            error: res.statusText
        };
    }

    async patchPersonalizationByKeyAsync(
        key: string,
        values: string
    ): Promise<ApiResponse<null>> {
        const uri = `${this.base}/${encodeURIComponent(key)}`;

        const options = await this.ensureFetchOptionsAsync("PATCH", values);

        const res = await fetch(uri, options);

        if (res.ok) {
            return {
                data: null
            };
        }

        if (res.status === 401) {
            return handle401Response();
        }

        if (res.status === 404) {
            log(`Personalization API not found.`, LogLevel.ERROR);
            return {
                data: null
            };
        }

        log(
            `Unknown error when attempting to save personalization values for ${key}. Status: ${res.statusText}`,
            LogLevel.ERROR
        );
        return {
            error: res.statusText
        };
    }
}
