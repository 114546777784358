import { Shipment } from "@models";

export const EMPTY_DIT = "N/A";

export function determineDaysInTransitDisplay(shipment: Shipment): string {
    const { daysInTransit, loadEmpty } = shipment;

    if (daysInTransit === undefined || daysInTransit === null) return EMPTY_DIT;

    const isEmpty = loadEmpty.toLowerCase() === "e";

    if (isEmpty) {
        return EMPTY_DIT;
    }

    return daysInTransit.toString();
}
