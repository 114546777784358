import { ApiResponse } from "../api-response";
import { SearchParameter, SerializableSearchResults } from ".";
import {
    CarType,
    ExceptionShipment,
    Personalization,
    SerializableShipment,
    ShipmentExceptions,
    SerializableShipmentMilestone,
    SerializableTripLocations
} from "@models";
import { SerializableExceptionShipmentDetail } from "@models/exception-shipment-detail";
import { OrderDetails } from "@models/container/order-details";

export interface ShipmentsApi {
    getShipmentExceptionsReport(
        carType: CarType,
        userId: string | null
    ): Promise<ApiResponse<ShipmentExceptions | null>>;

    /** Get Search Results */
    searchAsync(
        parameter: SearchParameter
    ): Promise<ApiResponse<SerializableSearchResults>>;

    getShipmentAsync(
        deliveryNumber: string
    ): Promise<ApiResponse<SerializableShipment | null>>;

    getShipmentMilestonesAsync(
        deliveryNumber: string
    ): Promise<ApiResponse<SerializableShipmentMilestone[] | null>>;

    getShipmentTripLocationsAsync(
        deliveryNumber: string
    ): Promise<ApiResponse<SerializableTripLocations | null>>;

    getExceptionShipmentDetailsAsync(
        exceptionType: ExceptionShipment,
        userId: string | null
    ): Promise<ApiResponse<SerializableExceptionShipmentDetail[] | null>>;

    getPersonalizationAsync(): Promise<ApiResponse<Personalization>>;

    getOrderDetails(
        orderNumber: string,
        deliveryNumber?: string
    ): Promise<ApiResponse<OrderDetails | null>>;
}

export const ServiceKey = "ShipmentsApi";
