import { DateTime, parseISO } from "@utilities/dates";

export interface ExceptionShipmentDetail {
    averageTransitTime?: number;
    carNumber: string;
    cuDateTime: DateTime;
    currentLocation: string;
    customerName: string;
    idleDays?: number;
    daysInTransit?: number;
    deliveryNumber: string;
    destination: string;
    etaCustomer: DateTime;
    loadEmpty: string;
    productName: string;
    shipDate: DateTime;
    shipToNumber: string;
    sightCode: string;
    carStatus: string;
    currentCarrier: string;
}

export type SerializableExceptionShipmentDetail = Omit<
    ExceptionShipmentDetail,
    "cuDateTime" | "etaCustomer" | "shipDate"
> & {
    cuDateTime: string;
    etaCustomer: string;
    shipDate: string;
};

export function transformExceptionShipmentDetails(
    exceptionShipmentDetails: SerializableExceptionShipmentDetail[]
): ExceptionShipmentDetail[] {
    return exceptionShipmentDetails.map((detail) =>
        transformExceptionShipmentDetail(detail)
    );
}

export function transformExceptionShipmentDetail(
    exceptionShipmentDetail: SerializableExceptionShipmentDetail
): ExceptionShipmentDetail {
    return {
        ...exceptionShipmentDetail,
        cuDateTime: parseISO(exceptionShipmentDetail.cuDateTime),
        etaCustomer: parseISO(exceptionShipmentDetail.etaCustomer),
        shipDate: parseISO(exceptionShipmentDetail.shipDate)
    };
}
