import { configureStore } from "@reduxjs/toolkit";
import ExceptionsReducer from "./Exceptions";
import PersonalizationReducer from "./Personalization";
import SearchReducer from "./Search";
import ShipmentReducer from "./Shipment";
import OceanReducer from "./Ocean";
import FavoritesReducer from "./Favorites";

const store = configureStore({
    reducer: {
        exceptions: ExceptionsReducer,
        personalization: PersonalizationReducer,
        search: SearchReducer,
        shipment: ShipmentReducer,
        ocean: OceanReducer,
        favorites: FavoritesReducer
    }
});

export default store;
export type DispatchType = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
