import React, { useMemo } from "react";
import { FilterRendererProps, TextFilterInput } from "@cpchem/covalence-ui";

export function SortedTextFilterInput(props: FilterRendererProps): JSX.Element {
    const { options } = props;
    const sortedTextOptions = useMemo(() => {
        return options.sort((a, b) => {
            return a.value?.toString().localeCompare(b.value?.toString());
        });
    }, [options]);
    const propsWithSortedOptions = {
        ...props,
        options: sortedTextOptions
    };

    return <TextFilterInput {...propsWithSortedOptions} />;
}
