import React from "react";
import { PageHeader } from "@components";
import { faHistory } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSearchParams } from "@hooks";
import { getPageTitle, PageTitles } from "./util";

interface Params {
    history?: string;
    q?: string;
}

export function SearchPageHeader(): JSX.Element {
    const { history, q: parameter } = useSearchParams<Params>();
    const isHistoryParamInURL = history === "true";
    const searchTermValue = parameter || "";

    const HistoricalShipmentsHeaderTitles: PageTitles = getPageTitle(
        isHistoryParamInURL,
        searchTermValue
    );

    let searchTitle = "Search";

    if (searchTermValue) {
        searchTitle += ` for "${searchTermValue}"`;
    }

    return isHistoryParamInURL ? (
        <PageHeader
            icon={<FontAwesomeIcon icon={faHistory} role="none" />}
            subtitle={HistoricalShipmentsHeaderTitles.subtitle}
            title={HistoricalShipmentsHeaderTitles.title}
        />
    ) : (
        <PageHeader title={searchTitle} />
    );
}
