import React from "react";
import { DrawerLinkGroup, Drawer } from "@components/drawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHouseBlank,
    faRectangleWide,
    faContainerStorage
} from "@fortawesome/pro-light-svg-icons";

import "./styles.scss";

const drawerLinks: DrawerLinkGroup[] = [
    {
        name: "",
        links: [
            {
                name: "Home",
                url: "/",
                icon: <FontAwesomeIcon className="fa-lg" icon={faHouseBlank} />,
                key: "home",
                isExact: true,
                testId: "home-button"
            }
        ]
    },
    {
        name: "Shipment Exceptions",
        links: [
            {
                name: "Loaded Hoppers",
                url: "/exceptions/hopper",
                icon: (
                    <FontAwesomeIcon
                        className="fa-lg"
                        icon={faContainerStorage}
                    />
                ),
                key: "loadedHoppers"
            },
            {
                name: "Loaded Tanks",
                url: "/exceptions/tank",
                icon: (
                    <FontAwesomeIcon className="fa-lg" icon={faRectangleWide} />
                ),
                key: "loadedTanks"
            }
        ]
    }
];

export function SnoDrawer(): JSX.Element {
    return <Drawer drawerLinkGroups={drawerLinks} />;
}
