import { ProductShipmentDetail, ProductInfo } from "@models/shipment";

export function getFirstProduct(
    products: ProductShipmentDetail[]
): ProductInfo {
    if (products.length === 0 || products[0].contents.length === 0) {
        return {
            name: "",
            code: "",
            quantity: 0
        } as ProductInfo;
    }

    const firstProduct = products[0].contents[0];

    return firstProduct;
}
