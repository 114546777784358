import React from "react";
import { NavLink } from "react-router-dom";
import { Container } from "@cpchem/covalence-ui";
import { DrawerLinkGroup } from "..";

import "../styles.scss";

export interface DrawerGroupProps {
    drawerGroup: DrawerLinkGroup;
}

export function DrawerGroup({ drawerGroup }: DrawerGroupProps): JSX.Element {
    return (
        <div>
            <>
                {drawerGroup.name && (
                    <Container className={"text-container hide"}>
                        <span className="group-name">
                            {drawerGroup.name ? drawerGroup.name : ""}
                        </span>
                    </Container>
                )}
            </>

            {drawerGroup.links.map((drawerLink) => (
                <li key={drawerLink.key}>
                    <NavLink
                        title={`${drawerLink.name} Tab`}
                        className={({ isActive }) =>
                            isActive ? "selected" : ""
                        }
                        to={drawerLink.url}
                        end={drawerLink.isExact}
                        data-testid={drawerLink.testId}
                    >
                        <Container className="nav-container">
                            {drawerLink.icon}
                            <span className="link-name">
                                {" "}
                                {drawerLink.name}
                            </span>
                        </Container>
                    </NavLink>
                </li>
            ))}
        </div>
    );
}
