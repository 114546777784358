import { Detail_Page } from "./pages/shipment-details/elements";
import { Results_Page } from "./pages/search-page/elements";
import { Exception_Page } from "./pages/exception-results-page/elements";

const App_Elements = {
    SearchTextBox: "search-box",
    ShipmentCentralIcon: "HomeIcon"
};

export { App_Elements, Detail_Page, Results_Page, Exception_Page };
