import { useSelector } from "react-redux";
import store from "@stores";
import { SerializableShipment } from "@models/shipment";

interface HookReturn {
    serializableShipment: SerializableShipment | undefined;
}

type RootState = ReturnType<typeof store.getState>;

export function useShipmentFromStore(deliveryNumber: string): HookReturn {
    const { shipmentDictionary } = useSelector(
        (state: RootState) => state.shipment
    );
    const shipmentDetails = shipmentDictionary[deliveryNumber];

    return {
        serializableShipment: shipmentDetails
    };
}
