import React from "react";
import { DataGrid, DataGridColumnDefinition } from "@cpchem/covalence-ui";
import { sortByDateTimeOn } from "../../search-page/sort/sort-by-datetime";
import { ExceptionShipmentDetail } from "../../../models";
import { ExceptionShipmentDetailRow } from "../models";
import { ExceptionDetailRow } from "./exception-detail-row";
import { SortedTextFilterInput } from "@components";

export type ExceptionResultsGridProps = {
    shipments: ExceptionShipmentDetail[];
    columnInclusions: string[];
};

const columns = [
    {
        Header: "Transport #",
        accessor: "carNumber",
        filterRenderer: SortedTextFilterInput,
        filterLabel: "Transport #",
        id: "carNumber",
        filter: "includesValue"
    },
    {
        Header: "Customer Name",
        accessor: "customerName",
        filterRenderer: SortedTextFilterInput,
        filterLabel: "Customer Name",
        id: "customerName",
        filter: "includesValue"
    },
    {
        Header: "Ship to #",
        accessor: "shipToNumber",
        filterRenderer: SortedTextFilterInput,
        filterLabel: "Ship to #",
        id: "shipToNumber",
        filter: "includesValue"
    },
    {
        Header: "Delivery #",
        accessor: "deliveryNumber",
        filterRenderer: SortedTextFilterInput,
        filterLabel: "Delivery #",
        id: "deliveryNumber",
        filter: "includesValue"
    },
    {
        Header: "Current Location",
        accessor: "currentLocation",
        filterRenderer: SortedTextFilterInput,
        filterLabel: "Current Location",
        id: "currentLocation",
        filter: "includesValue"
    },
    {
        Header: "Destination",
        accessor: "destination",
        filterRenderer: SortedTextFilterInput,
        filterLabel: "Destination",
        id: "destination",
        filter: "includesValue"
    },
    {
        Header: "Ship Date",
        accessor: "shipDate",
        sortType: sortByDateTimeOn("shipDate"),
        id: "shipDate"
    },
    {
        Header: "Loaded D.I.T.",
        accessor: "daysInTransit",
        filterRenderer: SortedTextFilterInput,
        filterLabel: "Loaded D.I.T.",
        id: "daysInTransit",
        filter: "includesValue"
    },
    {
        Header: "ETA Customer",
        accessor: "etaCustomer",
        sortType: sortByDateTimeOn("etaCustomer"),
        id: "etaCustomer"
    },
    {
        Header: "Sight Code",
        accessor: "sightCode",
        filterRenderer: SortedTextFilterInput,
        filterLabel: "Sight Code",
        id: "sightCode",
        filter: "includesValue"
    },
    {
        Header: "Idle Days",
        accessor: "idleDays",
        id: "idleDays"
    },
    {
        Header: "Car Status",
        accessor: "carStatus",
        filterRenderer: SortedTextFilterInput,
        filterLabel: "Car Status",
        id: "carStatus",
        filter: "includesValue"
    },
    {
        Header: "Loaded/ Empty",
        accessor: "loadEmpty",
        filterRenderer: SortedTextFilterInput,
        filterLabel: "Load Empty",
        id: "loadEmpty",
        filter: "includesValue"
    },
    {
        Header: "Avg. Transit Time",
        accessor: "averageTransitTime",
        filterRenderer: SortedTextFilterInput,
        filterLabel: "Avg. Transit Time",
        id: "averageTransitTime",
        filter: "includesValue"
    },
    {
        Header: "Route",
        accessor: "route",
        filterRenderer: SortedTextFilterInput,
        filterLabel: "Route",
        id: "route",
        filter: "includesValue"
    },
    {
        Header: "Current Railroad",
        accessor: "currentCarrier",
        filterRenderer: SortedTextFilterInput,
        filterLabel: "Current Railroad",
        id: "currentCarrier",
        filter: "includesValue"
    }
];

const getColumns = (
    columns: DataGridColumnDefinition[],
    excludedColumns: string[]
) => {
    return columns.filter((column: DataGridColumnDefinition) =>
        excludedColumns.includes(column.id as string)
    );
};

export function ExceptionResultsGrid({
    shipments,
    columnInclusions
}: ExceptionResultsGridProps): JSX.Element {
    const data = shipments as ExceptionShipmentDetailRow[];
    const gridProps = {
        data: data,
        columns: getColumns(columns, columnInclusions),
        useStickyHeaders: true,
        isSortable: true,
        isFilterable: true,
        rowRenderer: ExceptionDetailRow
    };

    return <DataGrid {...gridProps} />;
}
