import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppBar as CovalenceAppBar } from "@cpchem/covalence-ui";
import { LogoIcon } from "@icons";
import { useApp, useSearchParams } from "../../../hooks";

interface Params {
    q?: string;
}

export function AppBar(): JSX.Element {
    const navigate = useNavigate();
    const [term, setTerm] = useState("");
    const { q: parameter } = useSearchParams<Params>();

    const { applicationName } = useApp();

    function handleChangeSearchInputValue(value: string) {
        setTerm(value);
    }

    async function handleOnExecuteSearch() {
        navigate(`/search?q=${term}`);
    }

    function handleIconClick() {
        navigate("/");
    }

    useEffect(() => {
        if (parameter) {
            setTerm(parameter);
        }
    }, [parameter]);

    return (
        <CovalenceAppBar
            className="header sticky-header"
            icon={<LogoIcon />}
            applicationName={applicationName}
            showSearch
            searchValue={term}
            searchTooltip="Search shipments by Transport #, Delivery #, Ship to #, or PO #"
            onSearchValueChange={handleChangeSearchInputValue}
            onSearchPressEnter={handleOnExecuteSearch}
            onIconClick={handleIconClick}
        />
    );
}
