import React from "react";

export interface GridItemProps {
    name?: string;
    value?: string | number;
    children?: React.ReactNode;
    testId?: string;
    className?: string;
    warning?: React.ReactNode;
}

export function GridItem({
    name = "",
    value,
    children,
    testId,
    className,
    warning
}: GridItemProps): JSX.Element {
    className = className ? `${className} grid-item` : "grid-item";
    return (
        <div className={className} data-testid={testId}>
            <h4>{name}</h4>
            {value && (
                <div className="value">
                    <span className={warning ? "item-with-warning" : ""}>
                        {value}
                    </span>
                    {warning}
                </div>
            )}
            {!value && <>{children}</>}
        </div>
    );
}
