import { SerializableExceptionShipmentDetail } from "@models/exception-shipment-detail";
import {
    ExceptionResultsRouteParams,
    ExceptionShipment,
    generateExceptionReportBaseList,
    reportKeys,
    ReportName
} from "@models/exceptions";
import store, { DispatchType } from "@stores";
import {
    fetchExceptionShipmentDetails,
    setAvailableExceptions
} from "@stores/Exceptions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ExceptionRouteTemplate, routeMap } from "./constants";

type RootState = ReturnType<typeof store.getState>;

interface useExceptionDetailsReturn {
    columnInclusions: string[];
    exceptionShipmentType: ExceptionShipment;
    exceptionShipmentDetails: SerializableExceptionShipmentDetail[] | null;
    isLoading: boolean;
    reportTitle: ReportName | undefined;
    reportSubtitle: string | undefined;
}

export function useExceptionDetails(): useExceptionDetailsReturn {
    const { availableExceptions, exceptionReport, isLoading } = useSelector(
        (state: RootState) => state.exceptions
    );

    const dispatch = useDispatch<DispatchType>();
    const { activePersonalizationId } = useSelector(
        (x: RootState) => x.personalization
    );

    const { carType, exceptionType } = useParams<
        keyof ExceptionResultsRouteParams
    >() as ExceptionResultsRouteParams;

    const currentRoute: ExceptionRouteTemplate = `${carType}/${exceptionType}`;
    const exceptionShipmentType: ExceptionShipment =
        currentRoute in routeMap
            ? routeMap[currentRoute]
            : ExceptionShipment.unassigned;

    useEffect(() => {
        if (!availableExceptions) {
            const exceptionReportConfig =
                generateExceptionReportBaseList(reportKeys);
            dispatch(setAvailableExceptions(exceptionReportConfig));
        }
    }, [availableExceptions, dispatch]);

    useEffect(() => {
        async function refreshExceptionShipmentDetails(
            exceptionShipmentType: ExceptionShipment
        ) {
            dispatch(
                fetchExceptionShipmentDetails({
                    exceptionShipmentType,
                    activePersonalizationId
                })
            );
        }

        refreshExceptionShipmentDetails(exceptionShipmentType);
    }, [
        dispatch,
        exceptionShipmentType,
        exceptionType,
        activePersonalizationId
    ]);

    const reportConfig = availableExceptions?.find(
        ({ exceptionPath }) => exceptionType === exceptionPath
    );

    return {
        isLoading,
        columnInclusions: reportConfig?.columnInclusions ?? [],
        exceptionShipmentType:
            exceptionShipmentType || ExceptionShipment.unassigned,
        exceptionShipmentDetails: exceptionReport,
        reportSubtitle: reportConfig?.reportSubtitle,
        reportTitle: reportConfig?.reportName
    };
}
