import React from "react";
import { TripLocation } from "@models";
import { parseAddress, toLocaleDateTimeString } from "@utilities";
import {
    Modal,
    ModalContent,
    ModalFooter,
    Divider
} from "@cpchem/covalence-ui";
import { Detail_Page } from "../../elements";
import "./styles.scss";
import { FOOTER_COMMENT } from "../../constants";
import { LoadingDisplay } from "@components/loader";

export interface TripDetailsProps {
    transportNumber: string;
    route?: string;
    shipmentStatus: string;
    open: boolean;
    tripLocations: TripLocation[];
    onCloseClicked: () => void;
    testId?: string;
    isRailDetailsLoading: boolean;
}

export function TripDetailsModal({
    transportNumber,
    route,
    shipmentStatus,
    open,
    tripLocations,
    onCloseClicked,
    testId,
    isRailDetailsLoading
}: TripDetailsProps): JSX.Element {
    function handleOnClose() {
        onCloseClicked();
    }

    const tripDetailRows = tripLocations.map((detail, i) => {
        const formatedTimestamp = detail.timestamp
            ? toLocaleDateTimeString(detail.timestamp.timestampUtc)
            : "";

        const locationText = parseAddress(detail.location);
        const destinationText = parseAddress(detail.destination);

        return (
            <tr
                key={detail.id}
                data-testid={`${Detail_Page.clm_row_root}-${i}`}
                className={detail.isPlanned ? "planned" : undefined}
            >
                <td className="left">{formatedTimestamp}</td>
                <td>{detail.sightCode}</td>
                <td className="left">{locationText}</td>
                <td className="left">{destinationText}</td>
                <td>{detail.loadStatusCode}</td>
                <td>{detail.carrier}</td>
                {detail.isPlanned ? <td>Planned</td> : <td />}
            </tr>
        );
    });

    return (
        <Modal
            isOpen={open}
            onRequestClose={handleOnClose}
            title="Trip Details"
            className="trip-details-modal"
            testId={testId}
        >
            <ModalContent className="content" testId={`${testId}-content`}>
                <LoadingDisplay
                    showLoading={isRailDetailsLoading}
                    hideContentWhileLoading={false}
                >
                    <table className="basic-info">
                        <thead>
                            <tr>
                                <th>Transport #</th>
                                <th>Route</th>
                                <th>Shipment Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{transportNumber}</td>
                                <td>{route}</td>
                                <td>{shipmentStatus}</td>
                            </tr>
                        </tbody>
                    </table>
                    <Divider isSeparator />
                    <div className="clm-table">
                        <table>
                            <thead>
                                <tr>
                                    <th className="left">CLM Date</th>
                                    <th>Sight Code</th>
                                    <th className="left">Location</th>
                                    <th className="left">Destination</th>
                                    <th>Load/Empty</th>
                                    <th>Road</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>{tripDetailRows}</tbody>
                        </table>
                    </div>
                </LoadingDisplay>
            </ModalContent>
            <ModalFooter className="footer" testId={`${testId}-footer`}>
                <details>
                    <summary>Sight Code Legend</summary>
                    <p>
                        A - Arrival at Railyard | B - Bad Order | D - Arrival at
                        Destination | G - Bad Order Release | H - Car Held | I -
                        Equipment Offered in Interchange | J - Junction Delivery
                        | N - No Bill | P - Departure Railyard | R - Junction
                        Received | S - Storage Equipment | W - Released | X -
                        Pulled | Y - Customer Notified | Z - Actual Placement
                    </p>
                </details>
                <p className="footer-comment">{FOOTER_COMMENT}</p>
            </ModalFooter>
        </Modal>
    );
}
