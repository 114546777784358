import React from "react";
import { Favorite, FavoriteMap } from "@models/favorite";
import "./styles.scss";

export interface FavoriteWrapperProps {
    favorite: Favorite;
}

export function FavoriteWrapper({
    favorite
}: FavoriteWrapperProps): JSX.Element {
    const createComponentFromFavorite = (favorite: Favorite) => {
        const FavoriteView = FavoriteMap[favorite.componentKey];
        return <FavoriteView favorite={favorite} />;
    };

    return (
        <div className="favorite-wrapper">
            {createComponentFromFavorite(favorite)}
        </div>
    );
}
