import { LogEvent } from "@cpchem/logging";

export const PAGE_VIEW_TYPE = "SNO_PageView";

export type PageViewContents = {
    page: string;
    params: Record<string, unknown>;
};

export function createPageViewEvent(
    page: string,
    params: Record<string, unknown> | undefined = undefined
): LogEvent {
    const rawContents = {
        page,
        params
    };

    return {
        type: PAGE_VIEW_TYPE,
        contents: JSON.stringify(rawContents)
    };
}
