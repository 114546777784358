import { CarType, ExceptionShipment } from "@models";
import { ExceptionTypePath } from "@models/exceptions";

export type ExceptionRouteTemplate = `${CarType}/${ExceptionTypePath}`;

// hopper pathnames
export const badHopper = "hopper/bad-ordered";
export const delayedHopper = "hopper/delayed-shipments";
export const originDestinationHopper = "hopper/origin-destination";
export const loadStatusMismatchHopper = "hopper/load-status-mismatch";
export const etaDelayFlagHopper = "hopper/eta-delay-flag";
export const misroutedHopper = "hopper/misrouted";

// tank pathnames
export const badTank = "tank/bad-ordered";
export const delayedTank = "tank/delayed-shipments";
export const originDestinationTank = "tank/origin-destination";
export const loadStatusMismatchTank = "tank/load-status-mismatch";
export const etaDelayFlagTank = "tank/eta-delay-flag";
export const misroutedTank = "tank/misrouted";

/**
 * routeMap
 * this maps the current exception pathname to the enum needed to fetch
 * the exception's data.
 */
export const routeMap: Record<ExceptionRouteTemplate, ExceptionShipment> = {
    [badHopper]: ExceptionShipment.badOrderHopper,
    [delayedHopper]: ExceptionShipment.delayedHopper,
    [originDestinationHopper]: ExceptionShipment.originDestinationHopper,
    [originDestinationTank]: ExceptionShipment.originDestinationTank,
    [delayedTank]: ExceptionShipment.delayedTank,
    [badTank]: ExceptionShipment.badOrderTank,
    [loadStatusMismatchHopper]: ExceptionShipment.loadStatusMismatchHopper,
    [loadStatusMismatchTank]: ExceptionShipment.loadStatusMismatchTank,
    [etaDelayFlagHopper]: ExceptionShipment.etaDelayFlagHopper,
    [etaDelayFlagTank]: ExceptionShipment.etaDelayFlagTank,
    [misroutedHopper]: ExceptionShipment.misroutedHopper,
    [misroutedTank]: ExceptionShipment.misroutedTank
};
