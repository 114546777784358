import {
    SerializableShipment,
    SerializableShipmentMilestone,
    SerializableTripLocations
} from "@models";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    ServiceLocator,
    ShipmentsApi,
    ShipmentsApiServiceKey
} from "@services";
import { updateDictionary } from "@stores/utils";

export interface shipmentTripLocationsFetchParams {
    deliveryNumber: number;
}

export const fetchShipmentTripLocationsAsync = createAsyncThunk(
    "Shipment/fetchShipmentTripLocations",
    async (input: string) => {
        const api = ServiceLocator.get<ShipmentsApi>(ShipmentsApiServiceKey);
        const response = await api.getShipmentTripLocationsAsync(input);
        return response.data;
    }
);

export const fetchShipmentAsync = createAsyncThunk(
    "Shipment/fetchShipment",
    async (input: string) => {
        const api = ServiceLocator.get<ShipmentsApi>(ShipmentsApiServiceKey);
        const response = await api.getShipmentAsync(input);
        return response.data;
    }
);

export const fetchShipmentMilestonesAsync = createAsyncThunk(
    "Shipment/fetchShipmentMilestones",
    async (input: string) => {
        const api = ServiceLocator.get<ShipmentsApi>(ShipmentsApiServiceKey);
        const response = await api.getShipmentMilestonesAsync(input);
        return response.data;
    }
);

export interface railCarTripLocationsFetchParams {
    carrierName: string;
    railCarNumber: string;
}

export type ShipmentDictionary = {
    [id: string]: SerializableShipment | undefined;
};

interface ShipmentSliceState {
    isRailDetailsLoading: boolean;
    isShipmentDetailsLoading: boolean;
    isShipmentMilestonesLoading: boolean;
    shipmentDictionary: ShipmentDictionary;
    shipmentTripLocations: SerializableTripLocations | null;
    shipmentMilestones: SerializableShipmentMilestone[];
}

export const ShipmentSlice = createSlice({
    name: "shipment",
    initialState: {
        isShipmentDetailsLoading: false,
        isRailDetailsLoading: false,
        isShipmentMilestonesLoading: false,
        shipmentDictionary: {},
        shipmentTripLocations: null,
        shipmentMilestones: []
    } as ShipmentSliceState,

    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchShipmentTripLocationsAsync.pending, (state) => {
                state.isRailDetailsLoading = true;
            })
            .addCase(fetchShipmentAsync.pending, (state) => {
                state.isShipmentDetailsLoading = true;
            })
            .addCase(fetchShipmentMilestonesAsync.pending, (state) => {
                state.isShipmentMilestonesLoading = true;
            })
            .addCase(
                fetchShipmentTripLocationsAsync.fulfilled,
                (state, action) => {
                    state.isRailDetailsLoading = false;
                    if (action.payload) {
                        state.shipmentTripLocations = action.payload
                            ? action.payload
                            : null;
                    }
                }
            )
            .addCase(fetchShipmentAsync.fulfilled, (state, action) => {
                state.isShipmentDetailsLoading = false;
                if (action.payload) {
                    state.shipmentDictionary = updateDictionary(
                        state.shipmentDictionary,
                        action.payload.deliveryNumber.toString(),
                        action.payload
                    );
                }
            })
            .addCase(
                fetchShipmentMilestonesAsync.fulfilled,
                (state, action) => {
                    state.isShipmentMilestonesLoading = false;

                    if (action.payload) {
                        state.shipmentMilestones = action.payload
                            ? action.payload
                            : [];
                    }
                }
            );
    }
});

export default ShipmentSlice.reducer;
