import React, { useEffect } from "react";
import { useApp } from "@hooks";
import "./styles.scss";
import { Surface } from "@components";
import { trackEvent } from "@cpchem/logging";
import { createPageViewEvent } from "@utilities/log/page-view-event";
import { Landing_Page } from "./elements";
import { FourKitesSignOnBanner } from "@components/four-kites-sign-on-banner";
import { FavoritesDashboard } from "../../components/favorites/components/favoritesDashboard/favoritesDashboard";
import { useFavorites } from "../../components/favorites/hooks/use-favorites";
import { LoadingDisplay } from "@components/loader";

export function LandingPage(): JSX.Element {
    const { isFavoritesLoading } = useFavorites();
    const { setPageTitle } = useApp();

    useEffect(() => {
        setPageTitle(null);
        trackEvent(createPageViewEvent("Landing Page"));
    }, [setPageTitle]);

    return (
        <main className="main" data-testid={Landing_Page.main}>
            <h2>My Dashboard</h2>
            <LoadingDisplay showLoading={isFavoritesLoading}>
                <FavoritesDashboard />
                <Surface className="four-kites-sign-on">
                    <FourKitesSignOnBanner />
                </Surface>
            </LoadingDisplay>
        </main>
    );
}
