import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { parseAddress, toLocaleDateTimeString } from "@utilities";
import {
    fetchShipmentTripLocationsAsync,
    fetchShipmentMilestonesAsync
} from "@stores/Shipment";
import store, { DispatchType } from "@stores";
import { DetailsParams } from "@models/shipment";
import {
    Shipment,
    transformShipment,
    ShipmentMilestones,
    transformShipmentMilestones,
    TripLocation,
    transformTripLocations,
    TimestampInfo
} from "@models";
import { useParams } from "react-router-dom";
import { useShipmentFromStore } from "./use-shipment-from-store";

interface HookReturn {
    isRailDetailsLoading: boolean;
    isShipmentMilestonesLoading: boolean;
    tripLocations: TripLocation[];
    nextPlannedMove: string;
    railDestinationCarrier: string;
    railwayEtaCustomer: TimestampInfo | null;
    shipment: Shipment;
    shipmentMilestones: ShipmentMilestones;
}

export function useRailDetails(): HookReturn {
    type RootState = ReturnType<typeof store.getState>;

    const { shipmentTripLocations, isRailDetailsLoading } = useSelector(
        (state: RootState) => state.shipment
    );

    const { shipmentMilestones, isShipmentMilestonesLoading } = useSelector(
        (state: RootState) => state.shipment
    );

    const dispatch = useDispatch<DispatchType>();
    const { deliveryNumber } = useParams<
        keyof DetailsParams
    >() as DetailsParams;

    const { serializableShipment } = useShipmentFromStore(deliveryNumber);
    let shipment;

    useEffect(() => {
        function fetchShipmentTripDispatch() {
            dispatch(fetchShipmentTripLocationsAsync(deliveryNumber));
        }

        fetchShipmentTripDispatch();
    }, [deliveryNumber, dispatch]);

    useEffect(() => {
        function fetchShipmentMilestones() {
            dispatch(fetchShipmentMilestonesAsync(deliveryNumber));
        }

        fetchShipmentMilestones();
    }, [deliveryNumber, dispatch]);

    const {
        locations: tripLocations,
        destinationCarrier,
        nextPlannedMove: nextPlannedMoveDetails,
        etaCustomer
    } = transformTripLocations(shipmentTripLocations);

    if (serializableShipment) {
        shipment = transformShipment(serializableShipment);
    }

    const milestones = transformShipmentMilestones(shipmentMilestones);
    const nextPlannedMove = deriveNextPlannedMove(nextPlannedMoveDetails);

    return {
        tripLocations,
        nextPlannedMove,
        railDestinationCarrier: destinationCarrier?.toUpperCase(),
        railwayEtaCustomer: etaCustomer || null,
        shipment: shipment || ({} as Shipment),
        isRailDetailsLoading: isRailDetailsLoading,
        isShipmentMilestonesLoading: isShipmentMilestonesLoading,
        shipmentMilestones: milestones
    };
}

const deriveNextPlannedMove = (nextPlannedMove: TripLocation | null) => {
    return nextPlannedMove
        ? `${toLocaleDateTimeString(
              nextPlannedMove.timestamp.timestampUtc
          )} - ${parseAddress(nextPlannedMove.location)}`
        : "N/A";
};
