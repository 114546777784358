import React from "react";
import "./styles.scss";
import config from "@config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faUpRightFromSquare } from "@fortawesome/pro-light-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { Button } from "@cpchem/covalence-ui";

export function FourKitesSignOnBanner(): JSX.Element {
    const navigateToFourKites = function (url: string) {
        window.open(url, "_blank");
    };

    return (
        <div className="four-kites-sign-on-banner">
            <div className="ship-icon fa-stack fa-2x">
                <FontAwesomeIcon
                    icon={faCircle}
                    className="circle-background fa-stack-2x"
                />
                <FontAwesomeIcon icon={faGlobe} className="ship fa-stack-1x" />
            </div>
            <div className="four-kites-text">
                <p className="primary-text">
                    Access for Container and Truck visibility is now available!
                </p>
            </div>
            <div className="four-kites-button-link">
                {/* The <a> tag below has no functionality as the button captures the click event,
                    but it does allow the user to see where the button will take them. */}
                <a href={config.fourKitesSignOnUrl}>
                    <Button
                        color="primary"
                        icon={<FontAwesomeIcon icon={faUpRightFromSquare} />}
                        onClick={() =>
                            navigateToFourKites(config.fourKitesSignOnUrl)
                        }
                        text="FourKites User Access"
                    />
                </a>
            </div>
        </div>
    );
}
