export function getPageTitle(isHistoryParamInURL: boolean, searchTerm: string) {
    const titles = {
        withHistoryParam: {
            title: `Historical Shipments for ${searchTerm}`,
            subtitle: ""
        },
        withNoHistoryParam: {
            title: `Search Results for "${searchTerm}"`,
            subtitle: ""
        }
    };

    return isHistoryParamInURL
        ? titles.withHistoryParam
        : titles.withNoHistoryParam;
}

export type PageTitles = ReturnType<typeof getPageTitle>;
