import { Shipment, SerializableShipment, transformShipment } from "@models";

export interface SearchResults {
    isHistorical: boolean;
    searchTerm: string;
    shipments: Shipment[];
}

export type SerializableSearchResults = Omit<SearchResults, "shipments"> & {
    shipments: SerializableShipment[];
};

export function transformSearchResults(
    serializableSearchResults: SerializableSearchResults
): SearchResults {
    const shipments = [...serializableSearchResults.shipments].map((shipment) =>
        transformShipment(shipment)
    );

    return {
        ...serializableSearchResults,
        shipments
    };
}
