// API Response
import { ApiResponse } from "./api-response";
export type { ApiResponse };

// Service Locator
import ServiceLocator from "./service-locator";
export { ServiceLocator };

// Shipments
import {
    SearchParameter,
    SearchResults,
    ServiceKey as ShipmentsApiServiceKey,
    SearchResponse,
    ShipmentsApi,
    SerializableSearchResponse,
    SerializableSearchResults,
    ShipmentsApiImplementation,
    transformSearchResponse,
    transformSearchResults
} from "./shipments-api";

// Personalization
import {
    PersonalizationApi,
    ServiceKey as PersonalizationApiServiceKey,
    PersonalizationApiImplementation,
    PersonalizationResponse
} from "./personalization-api";

export type {
    SearchParameter,
    SearchResults,
    ShipmentsApi,
    SearchResponse,
    SerializableSearchResponse,
    SerializableSearchResults,
    ShipmentsApiImplementation,
    PersonalizationApi,
    PersonalizationApiImplementation,
    PersonalizationResponse
};
export { transformSearchResponse, transformSearchResults };
export { ShipmentsApiServiceKey, PersonalizationApiServiceKey };

// utilities
import { getFetchOptions, FetchOptions, FetchOptionMethod } from "./utilities";

export type { FetchOptions, FetchOptionMethod };
export { getFetchOptions };

export const AuthServiceKey = "Authentication";
