import React, { useMemo } from "react";
import { TextFilterInput, FilterRendererProps } from "@cpchem/covalence-ui";
import { deriveShipmentStatusOptions } from "./derive-shipment-status-options";

export type ShipStatusTextFilterProps = FilterRendererProps;

export function ShipmentStatusTextFilter(
    props: FilterRendererProps
): JSX.Element {
    const { id, options } = props;
    const shipmentStatusOptions = useMemo(() => {
        return deriveShipmentStatusOptions(id, options);
    }, [id, options]);

    const propsWithDerivedOptions = {
        ...props,
        options: shipmentStatusOptions
    };

    return <TextFilterInput {...propsWithDerivedOptions} />;
}
