import { ApiResponse } from "../api-response";
import { PersonalizationResponse } from "./models/personalization-response";

export interface PersonalizationApi {
    deletePersonalizationByKeyAsync(key: string): Promise<ApiResponse<null>>;

    getPersonalizationByKeyAsync(
        key: string
    ): Promise<ApiResponse<PersonalizationResponse | null>>;

    getAllPersonalizationAsync(): Promise<
        ApiResponse<PersonalizationResponse[] | null>
    >;

    patchPersonalizationByKeyAsync(
        key: string,
        values: string
    ): Promise<ApiResponse<PersonalizationResponse | null>>;
}

export const ServiceKey = "PersonalizationService";
