import { DataGridRow } from "@cpchem/covalence-ui";
import { ShipmentStatus } from "@models";
import { SHIPMENT_STATUS_ACCESSOR } from "../models";

export function filterByShipmentStatus(
    rows: DataGridRow[],
    columnId: string[], // eslint-disable-line  @typescript-eslint/no-unused-vars
    filterValues: string[]
): DataGridRow[] {
    const allCapsFilterValueList = filterValues.map((fv) => fv.toUpperCase());

    return rows.filter((r) => {
        const rowValue = (
            r.values[SHIPMENT_STATUS_ACCESSOR] as ShipmentStatus
        ).status.toUpperCase();

        return allCapsFilterValueList.includes(rowValue);
    });
}

/**
 * autoRemove Removes a GridFilter item from React table's filter list, if no filter selections are made.
 * This prevents the rendering of an empty filter pill
 */

filterByShipmentStatus.autoRemove = (val: string[]) => {
    return !val || !val.length;
};
