import React, { Fragment } from "react";
import { Comment as CommentModel } from "@models";
import { TripComment } from "../trip-comment";
import { Divider, Modal, ModalContent } from "@cpchem/covalence-ui";
import { HistoricalBadge } from "../historical-badge";
import { Detail_Page } from "../../elements";

export interface TripCommentsModalProps {
    isHistorical: boolean;
    open: boolean;
    onCloseClicked: () => void;
    values: CommentModel[];
    testId?: string;
}

export function TripCommentsModal(props: TripCommentsModalProps): JSX.Element {
    const { isHistorical } = props;

    function handleOnClose() {
        props.onCloseClicked();
    }
    const comments = props.values.map(
        (commentCell: CommentModel, index: number) => {
            const isLastComment = index === 0;
            return (
                <Fragment key={`tripComment-${index.toString()}`}>
                    <TripComment
                        isLastComment={isLastComment}
                        value={commentCell}
                        testId={`${Detail_Page.trip_comment}-${index}`}
                    />
                    <Divider isSeparator className="divider" />
                </Fragment>
            );
        }
    );
    return (
        <Modal
            isOpen={props.open}
            onRequestClose={handleOnClose}
            title="Comments"
            className="trip-comments-modal"
            data-testid={props.testId}
        >
            <div>
                <HistoricalBadge
                    className="historical-badge"
                    isHistorical={isHistorical}
                    testId="trip-comments-badge"
                />
                <ModalContent
                    className="content"
                    testId={`${props.testId}-content`}
                >
                    <div>{comments}</div>
                </ModalContent>
            </div>
        </Modal>
    );
}
