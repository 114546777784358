import { trackEvent } from "@cpchem/logging";
import { ManagerNoPersonalization, PageHeader, Surface } from "@components";
import { useApp } from "@hooks";
import {
    ExceptionShipmentDetail,
    transformExceptionShipmentDetails
} from "@models/exception-shipment-detail";
import { createPageViewEvent } from "@utilities/log/page-view-event";
import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useExceptionDetails } from "./hooks/use-exception-details";
import { ExceptionResultsGrid } from "./components";
import "./styles.scss";
import {
    CarType,
    ExceptionResultsRouteParams,
    ExceptionTypePath,
    ReportName
} from "@models/exceptions";
import { usePersonalization } from "../shipment-exceptions-page/hooks/use-personalization";
import { Select } from "@cpchem/covalence-ui";
import { LoadingDisplay } from "@components/loader";

interface SelectOption {
    key: string;
    isDisabled?: boolean;
    value: string;
    text: string;
}

interface SelectOptionGroup {
    key: string;
    label: string;
    options: SelectOption[];
    isDisabled?: boolean;
}

export function ExceptionResultsPage(): JSX.Element {
    const { setPageTitle } = useApp();
    const navigate = useNavigate();
    const {
        isLoading: isLoadingPersonalization,
        personalization,
        dispatchActivePersonalization,
        activePersonalizationId
    } = usePersonalization();
    const { carType, exceptionType } = useParams<
        keyof ExceptionResultsRouteParams
    >() as ExceptionResultsRouteParams;
    const {
        columnInclusions,
        isLoading,
        exceptionShipmentDetails,
        reportSubtitle,
        reportTitle
    } = useExceptionDetails();

    const results = useMemo(
        () => transformExceptionShipmentDetails(exceptionShipmentDetails || []),
        [exceptionShipmentDetails]
    );

    useEffect(() => {
        if (
            !Object.values(CarType).includes(carType) ||
            !Object.values(ExceptionTypePath).includes(exceptionType)
        ) {
            navigate("/");
        }
    }, [carType, exceptionType, navigate]);

    useEffect(() => {
        setPageTitle("Shipment Search");
    }, [setPageTitle]);

    useEffect(() => {
        trackEvent(
            createPageViewEvent("Exception Shipment Results", {
                resultCount: results.length,
                exceptionType
            })
        );
    });

    const sortedResults = results.sort(sortByNumberOfIdleDays);
    let options: (SelectOption | SelectOptionGroup)[] =
        personalization?.teamMembers?.map((x) => {
            return {
                key: x.id,
                value: x.id,
                text: x.fullName,
                disabled: false
            } as SelectOption;
        }) || [];

    const peersLabel = personalization?.isManager
        ? "My Direct Reports"
        : "My Peers";
    options = [
        {
            key: "0",
            value: personalization?.currentUserId
                ? personalization.currentUserId
                : "",
            text: personalization?.currentUserFullName
                ? personalization.currentUserFullName
                : ""
        },
        {
            key: "peerOrSubordinateGroup",
            label: peersLabel,
            // the original list of options only has SelectOptions in it
            options: options as SelectOption[]
        }
    ];

    const displayManagerNoResults =
        !isLoadingPersonalization &&
        personalization?.hasPersonalization &&
        personalization?.isManager &&
        activePersonalizationId === personalization.currentUserId;

    const displayPersonalization =
        !isLoadingPersonalization && personalization?.hasPersonalization;

    return (
        <div className="exception-results-page sticky-results">
            <div
                className={
                    `exception-results-header` +
                    `${
                        displayPersonalization
                            ? "-personalized"
                            : "-not-personalized"
                    }`
                }
            >
                <PageHeader
                    title={getTitle(carType, reportTitle as ReportName)}
                />
                {displayPersonalization && (
                    <div className="personalization-select-div">
                        <label>Personalized for:</label>
                        <Select
                            label=""
                            options={options}
                            onChange={(value) => {
                                dispatchActivePersonalization(value);
                            }}
                            className="personalization-select"
                            value={activePersonalizationId || ""}
                            isDisabled={isLoading}
                        />
                    </div>
                )}
            </div>
            {reportSubtitle && <h4 className="subtitle">{reportSubtitle}</h4>}

            {displayManagerNoResults ? (
                <ManagerNoPersonalization />
            ) : (
                <LoadingDisplay showLoading={isLoading}>
                    <Surface>
                        {!isLoadingPersonalization &&
                            sortedResults.length !== 0 && (
                                <ExceptionResultsGrid
                                    columnInclusions={columnInclusions}
                                    shipments={sortedResults}
                                />
                            )}
                        {!isLoading && !sortedResults.length && noResults()}
                    </Surface>
                </LoadingDisplay>
            )}
        </div>
    );
}

function getTitle(carType: string, reportTitle: string): string {
    return `Loaded ${
        carType === CarType.Hopper ? "Hoppers" : "Tanks"
    } - ${reportTitle}`;
}

function noResults(): JSX.Element {
    return <>No exception results.</>;
}

function sortByNumberOfIdleDays(
    a: ExceptionShipmentDetail,
    b: ExceptionShipmentDetail
) {
    return (b.idleDays || 0) - (a.idleDays || 0);
}
