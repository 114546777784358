import { DateTime } from "luxon";
export { DateTime };

/** Takes a known ISO-8601 formatted date string and returns a DateTime */
export function parseISO(input: string, timezone?: string): DateTime {
    if (timezone && timezone !== "Local") {
        return DateTime.fromISO(input, { zone: timezone });
    }

    return DateTime.fromISO(input, { setZone: true });
}

export function parseDate(input: string): DateTime {
    return DateTime.fromFormat(input, "D", { setZone: true });
}

/** Formats to a short date format and simple time format (e.g. 10/14/1983 1:30 PM or 10/14/1983 1:30 PM Eastern if timeZone supplied). Note - respects actual locale for formatting */
export function toLocaleDateTimeString(
    dateTime: DateTime,
    timeZone = "Local"
): string {
    let r = `${dateTime.toLocaleString(
        DateTime.DATE_SHORT
    )} ${dateTime.toLocaleString(DateTime.TIME_SIMPLE)}`;

    if (timeZone) {
        r += ` ${timeZone}`;
    }
    return r;
}

/** Formats to a short date format (e.g. 10/14/1983 or 10/14/1983 Eastern if timeZone supplied). Note - respects actual locale for formatting */
export function toLocaleDateString(
    dateTime: DateTime,
    timeZone?: string
): string {
    let r = dateTime.toLocaleString(DateTime.DATE_SHORT);

    if (timeZone) {
        r += ` ${timeZone}`;
    }
    return r;
}

export function isFutureTo(relativeTo: DateTime): {
    (date: DateTime): boolean;
} {
    return (date: DateTime) => {
        return date.toMillis() > relativeTo.toMillis();
    };
}
