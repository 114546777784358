import { getProvidedConfig, ProvidedConfig } from "@utilities/provided-config";

interface Config extends ProvidedConfig {
    env: string;
    connected: boolean;
}

// eslint-disable-next-line no-undef
const env = import.meta.env.MODE;
// eslint-disable-next-line no-undef
const envConnected = import.meta.env.VITE_CONNECTED
    ? import.meta.env.VITE_CONNECTED === "true"
    : false;
const connected = env === "production" || envConnected;

const providedConfig: ProvidedConfig = getProvidedConfig(env);

const combinedConfig: Config = {
    ...providedConfig,
    env: env || "unknown",
    connected
};

export default combinedConfig;
