import { Address } from "@models";

export function parseAddress(address?: Address): string {
    if (!address || (!address.city && !address.state)) {
        return "";
    }

    if (address.city && !address.state) {
        return address.city;
    }

    if (!address.city && address.state) {
        return address.state;
    }

    return `${address.city}, ${address.state}`;
}
