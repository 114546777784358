import React, { ErrorInfo } from "react";

interface ErrorBoundaryProps {
    children: React.ReactNode;
    errorPageComponent: JSX.Element;
    onErrorCallback: (error: Error, errorInfo: ErrorInfo) => void;
    testId?: string;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

export class ErrorBoundary extends React.Component<
    ErrorBoundaryProps,
    ErrorBoundaryState
> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        if (this.props.onErrorCallback) {
            this.props.onErrorCallback(error, errorInfo);
        }
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            if (this.props.errorPageComponent)
                return <>{this.props.errorPageComponent}</>;
            return <></>;
        }

        return this.props.children;
    }
}
