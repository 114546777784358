import React from "react";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStars } from "@fortawesome/pro-light-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { Surface } from "@components/surface";

export function FavoritesBanner(): JSX.Element {
    return (
        <Surface className="favorites-banner">
            <div className="favorites-icon fa-stack fa-2x">
                <FontAwesomeIcon
                    icon={faCircle}
                    className="circle-background fa-solid fa-stack-2x"
                />
                <FontAwesomeIcon
                    icon={faStars}
                    className="favorites fa-stack-1x"
                />
            </div>
            <div className="favorites-text">
                <p className="primary-text">
                    Favorites Widgets will display here.
                </p>
                <p>Navigate to each section to add your favorites.</p>
            </div>
        </Surface>
    );
}
