import { Comment, SerializableComment, transformComments } from "./comment";
import { DateTime, parseISO } from "@utilities/dates";
import { Address } from "./address";
import { TimestampInfo, SerializableTimestampInfo } from "./timestamp-info";

export interface Shipment {
    // id: string;
    carNumber: string;
    shipToNumber: string;
    customerName: string;
    deliveryNumber: number;
    origin: Address;
    destination: Address;
    sightCode: string;
    lotNumber: string;
    loadEmpty: string;
    route?: string;
    shipDate?: DateTime;
    comments: Comment[];
    daysInTransit?: number;
    isHistorical: boolean;
    etaDelayFlag?: number;
    salesOrderNumber: string;
    actualArrivalDate: TimestampInfo;
    carrierName: string;
    carrierScac?: string;
    companyCode: string;
    employeeName: string;
    eta: TimestampInfo;
    daysDelayed?: number;
    purchaseOrderNumber: string;
    shipmentStatus?: ShipmentStatus;
    latestLocation?: Address;
    latestLocationTimestamp: TimestampInfo;
    trackingUrl: string;
    trackingNumber: string;
    statusOfShipment: string;
    productShipmentDetails: ProductShipmentDetail[];
}

export type ProductShipmentDetail = {
    modeOfTransportation: string;
    productShipmentNumber: string;
    contents: ProductInfo[];
};

export type ProductInfo = {
    batch: string;
    material: string;
    name: string;
    code: string;
    quantity?: number;
    uom: string;
};

export type DetailsParams = {
    deliveryNumber: string;
};

export type ShipmentStatus = {
    status: string;
    date: DateTime;
};

export type SerializableShipmentStatus = {
    status: string;
    date: string;
};

export type SerializableShipment = Omit<
    Shipment,
    | "actualArrivalDate"
    | "latestLocationTimestamp"
    | "eta"
    | "shipDate"
    | "shipmentStatus"
    | "comments"
> & {
    actualArrivalDate: SerializableTimestampInfo;
    latestLocationTimestamp: SerializableTimestampInfo;
    eta: SerializableTimestampInfo;
    shipDate: string;
    shipmentStatus: SerializableShipmentStatus;
    comments: SerializableComment[];
};

export function transformShipment(
    serializableShipment: SerializableShipment
): Shipment {
    return {
        ...serializableShipment,
        // id: Math.random().toString(),
        actualArrivalDate: {
            timestampUtc: parseISO(
                serializableShipment.actualArrivalDate.timestampUtc,
                serializableShipment.actualArrivalDate.timezone
            ),
            timezone: serializableShipment.actualArrivalDate.timezone
        },
        latestLocationTimestamp: {
            timestampUtc: parseISO(
                serializableShipment.latestLocationTimestamp.timestampUtc,
                serializableShipment.latestLocationTimestamp.timezone
            ),
            timezone: serializableShipment.latestLocationTimestamp.timezone
        },
        eta: {
            timestampUtc: parseISO(
                serializableShipment.eta.timestampUtc,
                serializableShipment.eta.timezone
            ),
            timezone: serializableShipment.eta.timezone
        },
        shipDate: parseISO(serializableShipment.shipDate),
        shipmentStatus: isShipmentStatusValid(
            serializableShipment.shipmentStatus
        )
            ? {
                  status: serializableShipment.shipmentStatus.status,
                  date: parseISO(serializableShipment.shipmentStatus.date)
              }
            : undefined,
        comments: transformComments(serializableShipment.comments)
    };
}

const isShipmentStatusValid = (
    shipmentStatus: SerializableShipmentStatus
): boolean => {
    if (shipmentStatus && shipmentStatus.status && shipmentStatus.date) {
        return true;
    }
    return false;
};
