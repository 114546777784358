import { parseISO } from "@utilities/dates";
import { Address } from "./address";
import { TimestampInfo, SerializableTimestampInfo } from "./timestamp-info";

export interface TripLocation {
    id: string;
    location: Address;
    timestamp: TimestampInfo;
    sightCode?: string;
    carrier?: string;
    isPlanned: boolean;
    isNextPlannedMove: boolean;
    destination: Address;
    loadStatusCode?: string;
}

export interface TripLocations {
    locations: TripLocation[];
    destinationCarrier: string;
    nextPlannedMove: TripLocation | null;
    etaCustomer: TimestampInfo | undefined;
}

export type SerializableTripLocation = Omit<TripLocation, "timestamp"> & {
    timestamp: SerializableTimestampInfo;
};

export type SerializableTripLocations = Omit<
    TripLocations,
    "tripLocations" | "etaCustomer"
> & {
    locations: SerializableTripLocation[];
    etaCustomer: SerializableTimestampInfo;
};

export function transformTripLocation(
    serializableTripLocation: SerializableTripLocation
): TripLocation {
    return {
        ...serializableTripLocation,
        id: Math.random().toString(),
        timestamp: {
            timestampUtc: parseISO(
                serializableTripLocation.timestamp.timestampUtc,
                serializableTripLocation.timestamp.timezone
            ),
            timezone: serializableTripLocation.timestamp.timezone
        }
    };
}

export function transformTripLocations(
    serializableTripLocations: SerializableTripLocations | null
): TripLocations {
    if (!serializableTripLocations) {
        return {
            locations: [],
            destinationCarrier: "",
            nextPlannedMove: null,
            etaCustomer: undefined
        };
    }

    const locations = [...serializableTripLocations.locations].map((x) => {
        return transformTripLocation(x);
    });

    const nextPlannedMove = locations.find((x) => x.isNextPlannedMove === true);

    return {
        locations,
        destinationCarrier: serializableTripLocations.destinationCarrier,
        etaCustomer: serializableTripLocations.etaCustomer
            ? {
                  timestampUtc: parseISO(
                      serializableTripLocations.etaCustomer.timestampUtc,
                      serializableTripLocations.etaCustomer.timezone
                  ),
                  timezone: serializableTripLocations.etaCustomer.timezone
              }
            : undefined,
        nextPlannedMove: nextPlannedMove ?? null
    };
}
