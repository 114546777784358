import * as msal from "@azure/msal-browser";

export interface ProvidedConfig {
    api: {
        shipments: {
            /** Shipments API base */
            url: string;
            /** Needed Scopes */
            scopes: string[];
        };
        shipmentsV2: {
            url: string;
            scopes: string[];
        };
        personalization: {
            url: string;
            scopes: string[];
        };
    };
    msal: msal.Configuration;
    logging: {
        appInsights: {
            connectionString: string;
        };
        logRocket?: {
            appId?: string;
        };
    };
    launchDarklyClientSideId: string;
    fourKitesSignOnUrl: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getProvidedConfig(env?: string): ProvidedConfig {
    return env && env === "development"
        ? {
              api: {
                  shipments: {
                      url: "https://api-dev.cpchem.com/shipments/v1",
                      scopes: [
                          "https://api-dev.cpchem.com/Shipments.FullAccess"
                      ]
                  },
                  shipmentsV2: {
                      url: "https://api-dev.cpchem.com/shipments/v2",
                      scopes: [
                          "https://api-dev.cpchem.com/Shipments.FullAccess"
                      ]
                  },
                  personalization: {
                      url: "https://api-dev.cpchem.com/personalization/v1/personalization",
                      scopes: [
                          "https://api-dev.cpchem.com/Personalization.FullAccess"
                      ]
                  }
              },
              msal: {
                  auth: {
                      authority:
                          "https://login.microsoftonline.com/88b6c77b-f4e0-40c5-9fbb-df51a927179a",
                      clientId: "51b1821b-cccb-4ffa-9cd8-48ff17d99078",
                      redirectUri: "https://localhost:3000/auth_redirect"
                  }
              },
              logging: {
                  appInsights: {
                      connectionString:
                          "InstrumentationKey=01245263-e5d3-47db-85ec-0992111f8d6b;IngestionEndpoint=https://southcentralus-3.in.applicationinsights.azure.com/;LiveEndpoint=https://southcentralus.livediagnostics.monitor.azure.com/"
                  },
                  logRocket: {
                      appId: ""
                  }
              },
              launchDarklyClientSideId: "62d705f59f8c33112b5ee3a5",
              fourKitesSignOnUrl: "https://app-staging.fourkites.com/#/signin"
          }
        : getConfigFromIndex();
}

function getConfigFromIndex(): ProvidedConfig {
    const windowConfig = document.getElementById("config")?.innerHTML;
    if (windowConfig) {
        return JSON.parse(windowConfig);
    }
    throw "Unable to find config in page";
}
