import {
    toLocaleDateString,
    toLocaleDateTimeString,
    parseISO
} from "@utilities";
import { DateTime } from "@utilities/dates";

export interface ShipmentMilestone {
    message: string;
    messageDate: DateTime;
    status: string;
}

export interface SerializableShipmentMilestone {
    message: string;
    messageDate: string;
    status: string;
}

export interface ShipmentMilestones {
    notification: ShipmentMilestone | null;
    delivery: ShipmentMilestone | null;
    release: ShipmentMilestone | null;
}

export interface SerializableShipmentMilestones {
    notification: SerializableShipmentMilestone;
    delivery: SerializableShipmentMilestone;
    release: SerializableShipmentMilestone;
}

export function transformShipmentMilestone(
    serializableShipmentMilestone: SerializableShipmentMilestone
): ShipmentMilestone {
    return {
        ...serializableShipmentMilestone,
        messageDate: parseISO(serializableShipmentMilestone.messageDate)
    };
}

export function transformShipmentMilestones(
    serializableShipmentMilestones: SerializableShipmentMilestone[]
): ShipmentMilestones {
    const notification = serializableShipmentMilestones.find(
        (x) => x.message.toLowerCase() === "notification"
    );
    const delivery = serializableShipmentMilestones.find(
        (x) => x.message.toLowerCase() === "delivery"
    );
    const release = serializableShipmentMilestones.find(
        (x) => x.message.toLowerCase() === "release"
    );

    return {
        notification: notification
            ? transformShipmentMilestone(notification)
            : null,
        delivery: delivery ? transformShipmentMilestone(delivery) : null,
        release: release ? transformShipmentMilestone(release) : null
    };
}

export function transformMilestoneToDateString(
    milestone?: ShipmentMilestone | null | undefined
): string {
    return milestone ? toLocaleDateString(milestone.messageDate) : "N/A";
}

export function transformMilestoneToDateTimeString(
    milestone?: ShipmentMilestone | null | undefined
): string {
    return milestone ? toLocaleDateTimeString(milestone.messageDate) : "N/A";
}
