import React, { useState } from "react";
import { Button, Grid } from "@cpchem/covalence-ui";
import {
    getFirstProduct,
    parseAddress,
    toLocaleDateString,
    toLocaleDateTimeString
} from "@utilities";
import { GridItem } from "../grid-item";
import { TripDetailsModal } from "../trip-details-modal";
import { useRailDetails } from "../../hooks/use-rail-details";
import { TripCommentsModal } from "../trip-comments-modal";
import { TripComment } from "../trip-comment";
import {
    getShipmentStatus,
    getShipmentStatusWithDate
} from "./shipment-status-logic";
import { Link, useNavigate } from "react-router-dom";
import { Detail_Page } from "../../elements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHistory,
    faAlarmExclamation as EtaDelayFlagIcon
} from "@fortawesome/pro-light-svg-icons";
import "./styles.scss";
import { transformMilestoneToDateTimeString } from "@models/shipment-milestones";

interface Props {
    showTripDetails: boolean;
    onTripDetailsModalClosed: () => void;
    testId?: string;
}

export function RailDetails({
    showTripDetails,
    onTripDetailsModalClosed
}: Props): JSX.Element {
    const {
        tripLocations,
        isRailDetailsLoading,
        nextPlannedMove,
        railDestinationCarrier,
        railwayEtaCustomer,
        shipment,
        isShipmentMilestonesLoading,
        shipmentMilestones
    } = useRailDetails();

    const {
        shipDate,
        carNumber,
        deliveryNumber,
        customerName,
        shipToNumber,
        destination,
        latestLocationTimestamp,
        origin,
        loadEmpty,
        latestLocation,
        sightCode,
        productShipmentDetails,
        lotNumber,
        route,
        carrierName,
        comments,
        purchaseOrderNumber,
        isHistorical,
        etaDelayFlag
    } = shipment;

    const {
        code: productCode,
        name: productName,
        quantity
    } = getFirstProduct(productShipmentDetails);

    const navigate = useNavigate();

    const sortedCLM =
        tripLocations.length < 1
            ? []
            : [...tripLocations].sort(
                  (a, b) =>
                      b.timestamp.timestampUtc.toMillis() -
                      a.timestamp.timestampUtc.toMillis()
              );

    const shipmentStatus = getShipmentStatus(
        shipment,
        railDestinationCarrier,
        railwayEtaCustomer,
        shipmentMilestones
    );

    const shipmentStatusWithDate = getShipmentStatusWithDate(
        shipment,
        railDestinationCarrier,
        railwayEtaCustomer,
        shipmentMilestones
    );

    const latestLocationText = parseAddress(latestLocation);
    const originText = parseAddress(origin);
    const destinationText = parseAddress(destination);

    const formatedShipDate = shipDate ? toLocaleDateString(shipDate) : "";

    const formatedClmDateTime = latestLocationTimestamp
        ? toLocaleDateTimeString(latestLocationTimestamp.timestampUtc)
        : "";

    const actualNotificationText = transformMilestoneToDateTimeString(
        shipmentMilestones.notification
    );

    const actualDeliveryText = transformMilestoneToDateTimeString(
        shipmentMilestones.delivery
    );

    const actualReleaseText = transformMilestoneToDateTimeString(
        shipmentMilestones.release
    );

    const [showMoreComments, setShowMoreComments] = useState(false);
    const lastComment =
        (comments.length && (
            <TripComment isLastComment value={comments[0]} />
        )) ||
        "None";

    const displayCommentsButton = comments.length > 1;

    // milestones are needed to determine if a date or milestone text is displayed
    // checking for starting with "ETA " in case this field is changed in the future
    const etaText = isShipmentMilestonesLoading
        ? "N/A"
        : shipmentStatus.indexOf("ETA ") === 0
          ? shipmentStatus.substring(4)
          : shipmentStatus;

    const EtaDelayFlagWarning = function (): JSX.Element {
        return (
            <>
                {
                    <span className="eta-delay-flag-warning">
                        {<FontAwesomeIcon icon={EtaDelayFlagIcon} />} Delayed
                    </span>
                }
            </>
        );
    };

    function onTripCommentsModalClosed() {
        setShowMoreComments(false);
    }
    function onMoreCommentsClick() {
        setShowMoreComments((value) => !value);
    }

    function handleHistoryClick() {
        navigate(`/search?q=${encodeURIComponent(carNumber)}&history=true`);
    }

    return (
        <div
            className="railcar-details"
            data-testid={Detail_Page.railcar_details}
        >
            <a id="basic-info" href="#transit-info">
                Skip to Transit Information
            </a>
            <h3>Basic Information</h3>
            <Grid className="basic-info sub-grid">
                <div className="transport-number">
                    <GridItem
                        name="Transport #"
                        value={carNumber}
                        testId={Detail_Page.railcar_number}
                    />
                    <Button
                        className="history-button"
                        color="blue"
                        icon={<FontAwesomeIcon icon={faHistory} role="none" />}
                        onClick={handleHistoryClick}
                        size="medium"
                        text="History"
                        variant="text"
                        testId={Detail_Page.display_historical_button}
                    />
                </div>
                <GridItem
                    name="Delivery #"
                    value={deliveryNumber}
                    testId={Detail_Page.delivery_number}
                />
                <GridItem
                    name="Origin"
                    value={originText}
                    testId={Detail_Page.origin}
                />
                <GridItem
                    name="Customer Name"
                    value={customerName}
                    testId={Detail_Page.customer_name}
                />
                <GridItem
                    name="Destination"
                    value={destinationText}
                    testId={Detail_Page.destination}
                />
                <GridItem name="Ship to #">
                    <Link
                        title={`List of shipments for ship-to number: ${encodeURIComponent(
                            shipToNumber
                        )}`}
                        to={`/search?q=${encodeURIComponent(shipToNumber)}&redirect=false`}
                        data-testid={Detail_Page.ship_to_number}
                    >
                        {shipToNumber}
                    </Link>
                </GridItem>
                <GridItem name="PO #">
                    {purchaseOrderNumber && (
                        <Link
                            title={`Shipments on the same purchase order: ${encodeURIComponent(
                                purchaseOrderNumber
                            )}`}
                            to={`/search?q=${encodeURIComponent(
                                purchaseOrderNumber
                            )}&redirect=false`}
                            data-testid={Detail_Page.purchase_order_number}
                        >
                            {purchaseOrderNumber}
                        </Link>
                    )}
                </GridItem>
            </Grid>

            <a id="transit-info" href="#product-info">
                Skip to Product Information
            </a>
            <h3>Transit Information</h3>
            <Grid className="sub-grid">
                <GridItem
                    name="Ship Date"
                    value={formatedShipDate}
                    testId={Detail_Page.ship_date}
                />
                <GridItem
                    name="Route"
                    value={route?.toUpperCase()}
                    testId={Detail_Page.route}
                />
                <GridItem
                    name="Current Railroad"
                    value={carrierName}
                    testId={Detail_Page.current_railroad}
                />
                <GridItem
                    name="Load/Empty"
                    value={loadEmpty}
                    testId={Detail_Page.load_empty}
                />
                <GridItem
                    name="Sight Code"
                    value={sightCode}
                    testId={Detail_Page.sight_code}
                />
                <GridItem
                    name="Current Location"
                    value={latestLocationText}
                    testId={Detail_Page.current_location}
                />
                <GridItem
                    name="CLM Date/Time"
                    value={formatedClmDateTime}
                    testId={Detail_Page.clm_date_time}
                />
                <GridItem
                    name="Next Planned Move *"
                    value={nextPlannedMove}
                    testId={Detail_Page.next_planned_move}
                    className="next-planned-move"
                />
                <GridItem
                    name="ETA Customer"
                    value={etaText}
                    testId={Detail_Page.shipment_status}
                    className={etaDelayFlag ? "eta-delay-flag" : ""}
                    warning={etaDelayFlag ? <EtaDelayFlagWarning /> : null}
                />
                <GridItem
                    name="Actual Notification"
                    value={actualNotificationText}
                    testId={Detail_Page.actual_notification}
                />
                <GridItem
                    name="Actual Delivery"
                    value={actualDeliveryText}
                    testId={Detail_Page.actual_delivery}
                />
                <GridItem
                    name="Actual Release"
                    value={actualReleaseText}
                    testId={Detail_Page.actual_release}
                />
            </Grid>

            <a id="product-info" href="#comments-info">
                Skip to Comments Section
            </a>
            <h3>Product Information</h3>
            <Grid className="sub-grid">
                <GridItem
                    name="Product Name"
                    value={productName}
                    testId={Detail_Page.sub_grid}
                />
                <GridItem
                    name="Product Code"
                    value={productCode}
                    testId={Detail_Page.product_code}
                />
                <GridItem
                    name="Lot"
                    value={lotNumber}
                    testId={Detail_Page.lot}
                />
                <GridItem
                    name="Quantity"
                    value={quantity}
                    testId={Detail_Page.quantity}
                />
            </Grid>

            <div
                className="comments-section"
                data-testid={Detail_Page.comments_section}
            >
                <h3>Comments</h3>
                <div className="comments">
                    {lastComment}
                    {displayCommentsButton && (
                        <button
                            title="Show More Comments"
                            className="btn"
                            onClick={onMoreCommentsClick}
                            data-testid={`${Detail_Page.comments_section}-button`}
                        >
                            More Comments
                        </button>
                    )}
                </div>
            </div>

            <a id="return" href="#basic-info">
                Return to Top
            </a>

            <TripCommentsModal
                isHistorical={isHistorical}
                values={comments}
                open={showMoreComments}
                onCloseClicked={onTripCommentsModalClosed}
                testId={Detail_Page.trip_comments_modal}
            />

            <TripDetailsModal
                route={route}
                open={showTripDetails}
                transportNumber={carNumber}
                shipmentStatus={shipmentStatusWithDate}
                tripLocations={sortedCLM}
                onCloseClicked={onTripDetailsModalClosed}
                testId={Detail_Page.trip_details_modal}
                isRailDetailsLoading={isRailDetailsLoading}
            />
        </div>
    );
}
