import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderDetailsAsync, OrderDetailsFetchParams } from "@stores/Ocean";
import store, { DispatchType } from "@stores";
import { OrderDetails } from "@models";

interface HookReturn {
    isLoading: boolean;
    orderDetails: OrderDetails | undefined | null;
}

type RootState = ReturnType<typeof store.getState>;

export function useOrderDetails(): HookReturn {
    const { orderNumber, deliveryNumber } = useParams<
        keyof OrderDetailsFetchParams
    >() as OrderDetailsFetchParams;

    const { isLoading, orderDetails } = useSelector(
        (state: RootState) => state.ocean
    );
    const dispatch = useDispatch<DispatchType>();

    useEffect(() => {
        async function getOrderDetails(
            orderDetailsFetchParams: OrderDetailsFetchParams
        ) {
            dispatch(fetchOrderDetailsAsync(orderDetailsFetchParams));
        }
        if (
            !orderDetails ||
            orderDetails.orderNumber.toString() !== orderNumber ||
            (deliveryNumber &&
                !orderDetails.deliveries.find(
                    (delivery) => delivery.deliveryNumber === deliveryNumber
                ))
        ) {
            getOrderDetails({
                deliveryNumber,
                modeOfTransportation: "OCEAN",
                orderNumber
            } as OrderDetailsFetchParams);
        }
    }, [deliveryNumber, orderNumber, dispatch, orderDetails]);

    return {
        isLoading: isLoading,
        orderDetails
    };
}
