import React from "react";
import "./styles.scss";
import { faSquarePollHorizontal } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Surface } from "@components/surface";

export function ManagerNoPersonalization(): JSX.Element {
    return (
        <Surface className="manager-no-results-container">
            <FontAwesomeIcon className="fa-2xl" icon={faSquarePollHorizontal} />
            <div>
                <b>There are no exceptions available for you</b>
            </div>
            <div>
                Please select from the list of your direct reports, on the right
                to see exception reports.
            </div>
        </Surface>
    );
}
