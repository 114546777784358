/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";

export interface AppContextProps {
    applicationName: string;
    setApplicationName: (applicationName: string) => void;

    pageTitle: string | null;
    setPageTitle: (pageTitle: string | null) => void;

    applicationIcon: string | null;
    setApplicationIcon: (iconSrc: string) => void;
}

export const AppContext = React.createContext<AppContextProps>({
    applicationName: "",
    setApplicationName: () => {},
    pageTitle: null,
    setPageTitle: () => {},
    applicationIcon: null,
    setApplicationIcon: () => {}
});
