import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchShipmentAsync } from "@stores/Shipment";
import { DetailsParams, Shipment, transformShipment } from "@models/shipment";
import { useShipmentFromStore } from "./use-shipment-from-store";
import { DispatchType, RootState } from "@stores";

interface HookReturn {
    shipment: Shipment | undefined;
    isShipmentDetailsLoading: boolean;
}

export function useShipmentDetails(deliveryNumber = ""): HookReturn {
    let shipment: Shipment | undefined;

    const { isShipmentDetailsLoading } = useSelector(
        (state: RootState) => state.shipment
    );

    const deliveryNumberParam = (
        useParams<keyof DetailsParams>() as DetailsParams
    ).deliveryNumber;

    if (!deliveryNumber || deliveryNumber.length === 0) {
        deliveryNumber = deliveryNumberParam;
    }

    const { serializableShipment } = useShipmentFromStore(deliveryNumber);

    const dispatch = useDispatch<DispatchType>();

    if (serializableShipment) {
        shipment = transformShipment(serializableShipment);
    }

    useEffect(() => {
        async function getShipment(searchParam: string) {
            dispatch(fetchShipmentAsync(searchParam));
        }

        if (!serializableShipment) {
            getShipment(deliveryNumber);
        }
    }, [dispatch, deliveryNumber, serializableShipment]);

    return {
        shipment: shipment,
        isShipmentDetailsLoading: isShipmentDetailsLoading
    };
}
