import { ApiResponse } from "../api-response";
import {
    SerializableSearchResponse,
    SearchParameter,
    ShipmentsApi,
    SerializableSearchResults
} from ".";
import { FetchOptions, ServiceLocator } from "@services";
import config from "@config";
import {
    CarType,
    ExceptionShipment,
    Personalization,
    SerializableShipment,
    SerializableShipmentMilestone,
    SerializableTripLocations,
    ShipmentExceptions
} from "@models";
import { log, LogLevel } from "@cpchem/logging";
import {
    FetchinterceptorService,
    FetchServiceKey
} from "@services/fetchInterceptor";
import { SerializableExceptionShipmentDetail } from "@models/exception-shipment-detail";
import { OrderDetails } from "@models/container/order-details";

export class ShipmentsApiImplementation implements ShipmentsApi {
    private readonly base = config.api.shipments.url;
    private readonly baseV2 = config.api.shipmentsV2.url;
    private readonly scopes = config.api.shipments.scopes;

    private interceptor: FetchinterceptorService;

    constructor() {
        this.interceptor =
            ServiceLocator.get<FetchinterceptorService>(FetchServiceKey);
    }

    private async ensureFetchOptionsAsync(
        base = this.base,
        method = "GET"
    ): Promise<FetchOptions> {
        return this.interceptor.getFetchOptionsAsync(base, this.scopes, method);
    }

    async getShipmentExceptionsReport(
        carType: CarType,
        userId: string
    ): Promise<ApiResponse<ShipmentExceptions | null>> {
        const uri = `${this.baseV2}/exceptions?carType=${carType}`;
        const personalizedUri = `${uri}&employeeId=${userId}`;
        const options = await this.ensureFetchOptionsAsync(this.baseV2);
        const res = await fetch(userId ? personalizedUri : uri, options);

        if (res.ok) {
            const exceptionsReport = await res.json();
            return {
                data: exceptionsReport
            };
        }

        if (res.status === 404) {
            log(
                `Shipment Exception Data not found for Loaded ${carType}s`,
                LogLevel.ERROR
            );
            return {
                data: null
            };
        }

        log(
            `Unknown error for Shipment Exceptions: Loaded ${carType}s. Status ${res.statusText}`,
            LogLevel.ERROR
        );
        return {
            error: res.statusText
        };
    }

    async getShipmentMilestonesAsync(
        deliveryNumber: string
    ): Promise<ApiResponse<SerializableShipmentMilestone[] | null>> {
        const uri = `${this.baseV2}/shipments/${deliveryNumber}/milestones`;
        const options = await this.ensureFetchOptionsAsync();
        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            const shipmentMilestones = json as SerializableShipmentMilestone[];
            return {
                data: shipmentMilestones
            };
        }

        if (res.status === 404) {
            log(
                `Shipment Milestones not found for ${deliveryNumber}`,
                LogLevel.ERROR
            );
            return {
                data: null
            };
        }

        log(
            `Unknown error for ${deliveryNumber}. Status ${res.statusText}`,
            LogLevel.ERROR
        );
        return {
            error: res.statusText
        };
    }

    async getShipmentTripLocationsAsync(
        deliveryNumber: string
    ): Promise<ApiResponse<SerializableTripLocations | null>> {
        const uri = `${this.baseV2}/shipments/${encodeURIComponent(
            deliveryNumber.toString()
        )}/locations`;
        const options = await this.ensureFetchOptionsAsync();
        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            const shipmentTripDetails = json as SerializableTripLocations;

            return {
                data: shipmentTripDetails
            };
        }

        if (res.status === 404) {
            log(
                `Shipment Data not found for ${deliveryNumber}`,
                LogLevel.ERROR
            );
            return {
                data: null
            };
        }

        log(
            `Unknown error for ${deliveryNumber}. Status ${res.statusText}`,
            LogLevel.ERROR
        );
        return {
            error: res.statusText
        };
    }

    async searchAsync({
        isHistorical,
        parameter
    }: SearchParameter): Promise<ApiResponse<SerializableSearchResults>> {
        const uri = `${this.baseV2}/shipments/search?q=${encodeURIComponent(
            parameter
        )}&isHistorical=${isHistorical}`;

        const options = await this.ensureFetchOptionsAsync();
        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();

            const { shipments, isHistorical } =
                json as SerializableSearchResponse;

            return {
                data: {
                    shipments,
                    searchTerm: parameter,
                    isHistorical: isHistorical
                }
            };
        }

        if (res.status === 404) {
            log(`Search Data not found for ${parameter}`, LogLevel.ERROR);
            return {
                data: {
                    shipments: [],
                    searchTerm: parameter,
                    isHistorical: false
                }
            };
        }

        log(
            `Unknown error searching for ${parameter}. Status ${res.statusText}`,
            LogLevel.ERROR
        );
        return {
            error: res.statusText
        };
    }

    async getShipmentAsync(
        deliveryNumber: string
    ): Promise<ApiResponse<SerializableShipment | null>> {
        const uri = `${this.baseV2}/shipments/${encodeURIComponent(
            deliveryNumber
        )}`;

        const options = await this.ensureFetchOptionsAsync();
        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            const shipment = json as SerializableShipment;
            return {
                data: shipment
            };
        }
        if (res.status === 404) {
            log(
                `Shipment Data not found for ${deliveryNumber}`,
                LogLevel.ERROR
            );
            return {
                data: null
            };
        }

        log(
            `Unknown error for ${deliveryNumber}. Status ${res.statusText}`,
            LogLevel.ERROR
        );

        return {
            error: res.statusText
        };
    }

    async getExceptionShipmentDetailsAsync(
        exceptionType: ExceptionShipment,
        userId: string
    ): Promise<ApiResponse<SerializableExceptionShipmentDetail[] | null>> {
        const uri = `${this.baseV2}/exceptions/details/${exceptionType}`;
        const personalizedUri = `${uri}?aaid=${userId}`;
        const options = await this.ensureFetchOptionsAsync(this.baseV2);
        const response = await fetch(userId ? personalizedUri : uri, options);

        if (response.ok) {
            const json = await response.json();
            const exceptionShipmentDetails =
                json as SerializableExceptionShipmentDetail[];
            return {
                data: exceptionShipmentDetails
            };
        }

        if (response.status === 404) {
            log(
                `Exception Data not found for ${exceptionType}`,
                LogLevel.ERROR
            );
            return {
                data: []
            };
        }

        log(
            `Unknown error for ${exceptionType}. Status ${response.statusText}`,
            LogLevel.ERROR
        );
        return {
            error: response.statusText
        };
    }

    async getOrderDetails(
        orderNumber: string,
        deliveryNumber?: string
    ): Promise<ApiResponse<OrderDetails | null>> {
        let uri = `${this.base}/orders/${encodeURIComponent(orderNumber)}`;
        uri = deliveryNumber
            ? `${uri}/${encodeURIComponent(deliveryNumber)}`
            : uri;
        const options = await this.ensureFetchOptionsAsync();
        const response = await fetch(uri, options);
        if (response.ok) {
            const json = await response.json();
            const orderDetails = json as OrderDetails;
            return {
                data: orderDetails
            };
        }
        if (response.status === 404) {
            log(
                // we do not have the user's info here; comes from the backend
                `No order details found`,
                LogLevel.ERROR
            );
            return {
                data: null
            };
        }

        log(`Unknown error for fetching order details`, LogLevel.ERROR);
        return {
            error: response.statusText
        };
    }

    async getPersonalizationAsync(): Promise<ApiResponse<Personalization>> {
        const uri = `${this.baseV2}/users/get-team`;
        const options = await this.ensureFetchOptionsAsync(this.baseV2);
        const response = await fetch(uri, options);
        if (response.ok) {
            const json = await response.json();
            const personalization = json as Personalization;
            return {
                data: personalization
            };
        }
        if (response.status === 404) {
            log(
                // we do not have the user's info here; comes from the backend
                `No personalization found for logged-in user`,
                LogLevel.ERROR
            );
            return {
                data: {
                    hasPersonalization: false
                }
            };
        }

        log(
            `Unknown error for fetching personalization. Status ${response.statusText}`,
            LogLevel.ERROR
        );
        return {
            error: response.statusText
        };
    }
}
