import React from "react";
import { Badge } from "@cpchem/covalence-ui";
export interface SnoBadgeProps {
    status: string;
}

export function SnoBadge({ status }: SnoBadgeProps): JSX.Element {
    const getStatusColor = (status: string) => {
        if (status.includes("Bad Order")) {
            return "danger";
        }
        return "success";
    };
    return (
        <Badge variant="solid" text={status} color={getStatusColor(status)} />
    );
}
