import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { Modal, ThemeProvider } from "@cpchem/covalence-ui";
import { AppProvider, getdevAccountInformation } from "./hooks";
import config from "@config";
import store from "@stores";
import {
    ServiceLocator,
    ShipmentsApiServiceKey,
    AuthServiceKey,
    PersonalizationApiServiceKey
} from "@services";
import { ShipmentsApiImplementation } from "@services/shipments-api";
import { PersonalizationApiImplementation } from "@services/personalization-api";

import { BrowserRouter as Router } from "react-router-dom";

import "./index.scss";
import "@cpchem/covalence-ui/lib/default-theme.css";
import "@cpchem/covalence-ui/lib/style.css";
import { configureLogging } from "@utilities/log/configure-logging";
import { configureAppInsightsLogging } from "@utilities/log/configure-app-insights";
import { configuredLogRocket } from "@utilities/log/configure-log-rocket";
import {
    AppIngishtsFetchinterceptorImplementation,
    FetchServiceKey
} from "@services/fetchInterceptor";
import { ErrorBoundary } from "./app/components/error-boundary";
import { ErrorMessage, LogError } from "./app/components/error-message";
import { AuthManager } from "@cpchem/azure-auth";
import { AuthNBoundary } from "./app/components/authN-boundary";

const authSettings = {
    clientId: config.msal.auth.clientId,
    authority: config.msal.auth.authority ?? "",
    redirectUri: config.msal.auth.redirectUri ?? "",
    domainHint: "cpchem.com"
};
let authManagerInstance: AuthManager;

// Configure Logging Sinks
const appInsights = configureLogging(config.env);
configureAppInsightsLogging(appInsights);
configuredLogRocket(config.logging.logRocket?.appId);

if (!config.connected) {
    authManagerInstance = AuthManager.getInstance();

    const mockAccountGenerator = await getdevAccountInformation;
    authManagerInstance.loadConfiguration(
        "Dev",
        undefined,
        mockAccountGenerator
    );
} else {
    authManagerInstance = AuthManager.getInstance();
    authManagerInstance.loadConfiguration("MSAL", authSettings);
}
// Configure Services in Service Locator
ServiceLocator.set(AuthServiceKey, authManagerInstance);
ServiceLocator.set(
    FetchServiceKey,
    new AppIngishtsFetchinterceptorImplementation(
        authManagerInstance,
        appInsights
    )
);
ServiceLocator.set(ShipmentsApiServiceKey, new ShipmentsApiImplementation());
ServiceLocator.set(
    PersonalizationApiServiceKey,
    new PersonalizationApiImplementation()
);

const rootElement = document.getElementById("root");
if (!rootElement) {
    throw new Error("Failed to find the root element");
}
const root = ReactDOM.createRoot(rootElement);

if (rootElement) {
    Modal.setAppElement(rootElement);
}

root.render(
    <ErrorBoundary
        errorPageComponent={ErrorMessage()}
        onErrorCallback={LogError}
        testId="errorBoundary"
    >
        <ThemeProvider
            supportedThemes={[{ name: "light" }]}
            defaultTheme="light"
        >
            <AppProvider applicationName="Shipment Central">
                <Router>
                    <Provider store={store}>
                        <AuthNBoundary />
                    </Provider>
                </Router>
            </AppProvider>
        </ThemeProvider>
    </ErrorBoundary>
);
