import React from "react";
import "./styles.scss";
import { Card, Divider } from "@cpchem/covalence-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/pro-light-svg-icons";

export interface SnoCardProps {
    title?: string;
    icon?: IconDefinition;
    subIcon?: IconDefinition;
    // large creates a card with 2 columns
    large?: boolean;
    children?: JSX.Element | JSX.Element[];
    className?: string;
    subtitle?: string;
}

export function SnoCard({
    title,
    icon,
    large,
    children,
    className,
    subtitle,
    subIcon
}: SnoCardProps): JSX.Element {
    let combinedClassName = "card";

    if (className) {
        combinedClassName += ` ${className}`;
    }
    if (large) {
        combinedClassName += " large";
    }
    return (
        <Card className={combinedClassName}>
            <div className="card-header">
                <div>
                    {icon && <FontAwesomeIcon className="fa-sm" icon={icon} />}
                    <span>{title}</span>
                </div>
                <div className="card-subtitle">
                    <div>
                        {subIcon && (
                            <FontAwesomeIcon className="fa-sm" icon={subIcon} />
                        )}
                        <span>{subtitle}</span>
                    </div>
                </div>
            </div>
            <Divider variant="middle" />
            <div className="card-body">{children}</div>
        </Card>
    );
}
