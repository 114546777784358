import { Shipment, ShipmentMilestones, TimestampInfo } from "@models";
import { DELIVERED, ETA, NOTIFIED, RELEASED } from "./constants";
import { determineDisplayedShipmentStatusETA } from "./shipment-eta-logic";
import {
    createMilestone,
    getFormattedETADate,
    getFormattedShipmentStatusWithDate
} from "./utils";

export type Status =
    | "Customer Notified"
    | "Delivered"
    | "ETA"
    | "Released Empty";

export type MileStone = {
    status: Status;
    formattedStatusDate: string;
};

export function getShipmentStatusWithDate(
    shipment: Shipment,
    destinationCarrier: string,
    railwayETA: TimestampInfo | null,
    milestones: ShipmentMilestones
): string {
    const { status, formattedStatusDate } = determineActualOrETAMilestone(
        shipment,
        destinationCarrier,
        railwayETA,
        milestones
    );

    if (status === ETA) return formattedStatusDate;

    return getFormattedShipmentStatusWithDate(status, formattedStatusDate);
}

export function getShipmentStatus(
    shipment: Shipment,
    destinationCarrier: string,
    railwayETA: TimestampInfo | null,
    milestones: ShipmentMilestones
): string {
    const { status, formattedStatusDate } = determineActualOrETAMilestone(
        shipment,
        destinationCarrier,
        railwayETA,
        milestones
    );

    if (status === ETA) return formattedStatusDate;

    return status;
}

export function determineActualOrETAMilestone(
    shipment: Shipment,
    destinationCarrier: string,
    railwayETA: TimestampInfo | null,
    milestones: ShipmentMilestones
): MileStone {
    const { delivery, notification, release } = milestones;

    if (release) {
        return createMilestone(RELEASED, release.messageDate);
    }

    if (delivery) {
        return createMilestone(DELIVERED, delivery.messageDate);
    }

    if (notification) {
        return createMilestone(NOTIFIED, notification.messageDate);
    }

    const derivedETA = determineDisplayedShipmentStatusETA(
        shipment,
        destinationCarrier,
        railwayETA
    );

    return {
        status: ETA,
        formattedStatusDate: getFormattedETADate(derivedETA)
    };
}
