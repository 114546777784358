export const Results_Page = {
    active_result_row: "active-result-row",
    historical_result_row: "historical-result-row",
    no_results: "no-results",
    results: "results",
    search_guide: "search-guide",
    search_header: "search-header",
    search_page: "search-page",
    search_surface: "search-surface",
    results_count: "results-table-top-result-count",
    results_table: "results-table"
};
