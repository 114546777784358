import React from "react";
import { Button } from "@cpchem/covalence-ui";
import { useFavorites } from "../../hooks/use-favorites";
import { faStar as faStarSolid } from "@fortawesome/pro-solid-svg-icons";
import { faStar as faStarOutline } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Favorite } from "@models/favorite";

export interface FavoriteButtonProps {
    favorite: Favorite;
}

export function FavoriteButton({ favorite }: FavoriteButtonProps): JSX.Element {
    const { dispatchToggleFavorite, isFavorite } = useFavorites();

    return (
        <div>
            <Button
                testId="favorite-button"
                onClick={() => dispatchToggleFavorite(favorite)}
                variant="text"
                size="medium"
                color="blue"
                icon={
                    <FontAwesomeIcon
                        title="Toggle Favorite"
                        className="fa-lg"
                        icon={
                            isFavorite(favorite.id)
                                ? faStarSolid
                                : faStarOutline
                        }
                        color={isFavorite(favorite.id) ? "gold" : "blue"}
                    />
                }
            />
        </div>
    );
}
