import { DataGridRow } from "@cpchem/covalence-ui";
import { ShipmentStatus } from "@models";
import { ResultRowData, SHIPMENT_STATUS_ACCESSOR } from "../models";

const StatusPriority: Record<string, number> = {
    ETA: 0,
    NOTIFIED: 1,
    DELIVERED: 2,
    RELEASED: 3
};

export function sortByShipmentStatus(
    left: DataGridRow,
    right: DataGridRow,
    columnId: string, // eslint-disable-line  @typescript-eslint/no-unused-vars
    isDescending: boolean // eslint-disable-line  @typescript-eslint/no-unused-vars
): number {
    const leftStatus = shipmentStatusFor(left);
    const rightStatus = shipmentStatusFor(right);
    if (!leftStatus && !rightStatus) {
        // retain position if both undefined
        return 0;
    }
    if (!leftStatus) {
        return -1;
    }
    if (!rightStatus) {
        return 1;
    }

    if (leftStatus.status === rightStatus.status) {
        return leftStatus.date.toMillis() - rightStatus.date.toMillis();
    }

    const leftPriority = StatusPriority[leftStatus.status.toUpperCase()];
    const rightPriority = StatusPriority[rightStatus.status.toUpperCase()];

    return leftPriority - rightPriority;
}

function shipmentStatusFor(row: DataGridRow): ShipmentStatus | undefined {
    return (row.values as ResultRowData)[SHIPMENT_STATUS_ACCESSOR];
}
