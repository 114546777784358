import React from "react";
import { Link } from "react-router-dom";
import { DataGridRow } from "@cpchem/covalence-ui";
import { HistoricalResultRowData } from "../../models";
import { toLocaleDateString } from "@utilities";
import { Results_Page } from "../../elements";

export function ResultRow(row: DataGridRow): JSX.Element {
    const {
        icon,
        deliveryNumber,
        carNumber,
        customerName,
        shipToNumber,
        shipDate,
        productName,
        purchaseOrderNumber
    } = row.values as HistoricalResultRowData;

    return (
        <tr
            key={deliveryNumber}
            data-testid={`${Results_Page.historical_result_row}-${row.index}`}
        >
            <td>{icon}</td>
            <td className="left">
                <Link
                    to={`/shipments/${deliveryNumber}`}
                    title={`Shipment details for railcar number: ${carNumber}`}
                >
                    {carNumber}
                </Link>
            </td>
            <td className="left">{customerName}</td>
            <td className="left">{shipToNumber}</td>
            <td className="left">{deliveryNumber}</td>
            <td className="left">{purchaseOrderNumber}</td>
            <td className="left">
                {shipDate ? toLocaleDateString(shipDate) : "N/A"}
            </td>
            <td className="left">{productName}</td>
        </tr>
    );
}
