import React, { useEffect, useState } from "react";
import { AppContext } from "./context";

export interface AppProviderProps {
    children: React.ReactNode;
    applicationName: string;
}

export function AppProvider({
    applicationName,
    children
}: AppProviderProps): JSX.Element {
    const [currentApplicationName, setApplicationName] =
        useState<string>(applicationName);
    const [pageTitle, setPageTitle] = useState<string | null>(null);
    const [applicationIcon, setApplicationIcon] = useState<string | null>(null);

    useEffect(() => {
        const parts = [];
        if (pageTitle) {
            parts.push(pageTitle);
        }
        if (currentApplicationName) {
            parts.push(currentApplicationName);
        }
        const docTitle = parts.join(" - ");
        document.title = docTitle;
    }, [currentApplicationName, pageTitle]);

    return (
        <AppContext.Provider
            value={{
                applicationIcon,
                applicationName: currentApplicationName,
                pageTitle,
                setApplicationIcon,
                setApplicationName,
                setPageTitle
            }}
        >
            {children}
        </AppContext.Provider>
    );
}
