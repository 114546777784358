export const Component_Elements = {
    guide_container: "guide-container",
    guide_contents: "guide-contents",
    guide_title: "guide-title",
    page_header: "page-header",
    page_header_children: "page-header-children",
    page_subtitle: "page-subtitle",
    page_title: "page-title",
    surface: "surface"
};
