import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { CarType, ExceptionTypePath, ReportName } from "@models/exceptions";

export interface Widget {
    count: number;
    icon: IconProp;
    name: ReportName;
    path: `/exceptions/${CarType}/${ExceptionTypePath}`;
}

export function Widget({ count, icon, name, path }: Widget): JSX.Element {
    return (
        <Link to={path} data-testid={name}>
            <div className="widget">
                <h3>{name}</h3>
                <div className="count">
                    <span className="count-value">{count}</span>
                    <span className="count-title">Cars</span>
                </div>
                <FontAwesomeIcon className="widget-icon" icon={icon} />
            </div>
        </Link>
    );
}
