import { TimestampInfo, Shipment } from "@models";
import {
    toLocaleDateString,
    toLocaleDateTimeString,
    DateTime
} from "@utilities/dates";

export type EtaFormatter = (date: DateTime, timezone: string) => string;

export function determineDisplayedShipmentStatusETA(
    shipment: Shipment,
    destinationCarrier: string,
    railwayEta: TimestampInfo | null
): string {
    const useRailETA = shipment
        ? shipment &&
          shipment.carrierName &&
          destinationCarrier?.toUpperCase() ===
              shipment.carrierName.toUpperCase()
        : false;

    if (useRailETA && railwayEta) {
        return toLocaleDateTimeString(railwayEta.timestampUtc, "Local");
    }

    if (shipment?.eta === null || shipment?.eta?.timestampUtc === null) {
        return "N/A";
    }

    return toLocaleDateString(shipment.eta.timestampUtc, "Local");
}
