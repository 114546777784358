import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { AccountInformation } from "@cpchem/azure-auth";
import { getUserTraits } from "@utilities/log/user-traits";

export function configuredLogRocket(appId: string | undefined): void {
    if (!appId) return;

    LogRocket.init(appId);
    setupLogRocketReact(LogRocket);
}

export function setLogRocketUserId(userAcct: AccountInformation): void {
    LogRocket.identify(userAcct.username, getUserTraits(userAcct));
}
