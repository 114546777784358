import {
    faClock as DelayedShipmentIcon,
    faScrewdriverWrench as BadOrderedIcon,
    faContainerStorage as LoadedHopperIcon,
    faRectangleWide as LoadedTankIcon,
    faMapMarkerAltSlash as OriginDestinationIcon,
    faRampLoading as LoadStatusMismatchIcon,
    faAlarmExclamation as EtaDelayFlagIcon,
    faCompassSlash as MisroutedIcon
} from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

// enums
export enum CarType {
    Hopper = "hopper",
    Tank = "tank"
}

export enum ExceptionShipment {
    unassigned = 0,
    delayedHopper,
    delayedTank,
    badOrderHopper,
    badOrderTank,
    originDestinationHopper,
    originDestinationTank,
    loadStatusMismatchHopper,
    loadStatusMismatchTank,
    etaDelayFlagHopper,
    etaDelayFlagTank,
    misroutedHopper,
    misroutedTank
}

export enum ExceptionTypePath {
    BadOrder = "bad-ordered",
    Delayed = "delayed-shipments",
    OriginDestination = "origin-destination",
    LoadStatusMismatch = "load-status-mismatch",
    EtaDelayFlag = "eta-delay-flag",
    Misrouted = "misrouted"
}

export enum ReportName {
    BadOrder = "Bad Ordered Cars",
    Delayed = "Delayed Shipments",
    OriginDestination = "Origin/Destination > 5 Days",
    LoadStatusMismatch = "Car Status 1/Empty & 3/Loaded",
    EtaDelayFlag = "ETA Delay",
    Misrouted = "Misrouted Railcars"
}

export enum ReportSubtitle {
    BadOrder = "Cars that have been moved to a Repair Track. Sight Code B: Currently bad ordered; G: Released from bad order status.",
    Delayed = "Cars that have not moved for more than 72 hours are defined as delayed shipments.",
    OriginDestination = "Origin is TX, destination is not TX, and current location is TX, 5+days after shipment date.",
    LoadStatusMismatch = "List of railcars with Car Status 1 on the way to the customer but they are empty and Car Status 3 on the way back from the customer but they are loaded.",
    EtaDelayFlag = "ETA Delays are defined as shipments that have a current ETA that is greater than 24 hours from what was reported 24 hours ago.",
    Misrouted = "Railcars delivered to railroad not indicated in the route description."
}

// interfaces & types
export type CarTypeParam = { carType: CarType };

export type ExceptionResultsRouteParams = {
    carType: CarType;
    exceptionType: ExceptionTypePath;
};

export type ExceptionShipmentType =
    | "BadOrderHopper"
    | "BadOrderTank"
    | "DelayedHopper"
    | "DelayedTank"
    | "OriginDestinationHopper"
    | "OriginDestinationTank"
    | "LoadStatusMismatchHopper"
    | "LoadStatusMismatchTank"
    | "EtaDelayFlagHopper"
    | "EtaDelayFlagTank"
    | "MisroutedHopper"
    | "MisroutedTank";

export type ExceptionTypeKeys = keyof typeof ExceptionTypePath;

export type ExceptionWidgetIcons = Record<
    ExceptionTypeKeys | CarType,
    IconProp
>;

export type ShipmentExceptions = ExceptionsReportResponse[];

export interface ExceptionsReportResponse {
    count: number;
    description: string;
    exceptionShipment: ExceptionShipmentType;
}
export interface ExceptionReport {
    columnInclusions: string[];
    exceptionPath: ExceptionTypePath;
    icon: IconProp;
    key: ExceptionTypeKeys;
    reportName: ReportName;
    reportSubtitle?: string;
}

export interface ExceptionReportWidget extends ExceptionReport {
    count: number;
    reportUrl: `/exceptions/${CarType}/${ExceptionTypePath}`;
}

// Exception report config
export const iconMapper: ExceptionWidgetIcons = {
    BadOrder: BadOrderedIcon,
    Delayed: DelayedShipmentIcon,
    LoadStatusMismatch: LoadStatusMismatchIcon,
    OriginDestination: OriginDestinationIcon,
    EtaDelayFlag: EtaDelayFlagIcon,
    hopper: LoadedHopperIcon,
    tank: LoadedTankIcon,
    Misrouted: MisroutedIcon
};

const exceptionColumnInclusionMap: Record<ExceptionTypeKeys, string[]> = {
    BadOrder: [
        "carNumber",
        "deliveryNumber",
        "customerName",
        "shipToNumber",
        "currentLocation",
        "shipDate",
        "etaCustomer",
        "sightCode"
    ],
    Delayed: [
        "carNumber",
        "deliveryNumber",
        "customerName",
        "shipToNumber",
        "currentLocation",
        "shipDate",
        "etaCustomer",
        "sightCode",
        "idleDays",
        "averageTransitTime"
    ],
    OriginDestination: [
        "carNumber",
        "deliveryNumber",
        "customerName",
        "currentLocation",
        "destination",
        "shipDate",
        "daysInTransit",
        "etaCustomer",
        "sightCode"
    ],
    LoadStatusMismatch: [
        "carNumber",
        "deliveryNumber",
        "customerName",
        "shipToNumber",
        "currentLocation",
        "shipDate",
        "etaCustomer",
        "sightCode",
        "carStatus",
        "loadEmpty"
    ],
    EtaDelayFlag: [
        "carNumber",
        "deliveryNumber",
        "customerName",
        "shipToNumber",
        "currentLocation",
        "shipDate",
        "etaCustomer",
        "sightCode",
        "idleDays",
        "averageTransitTime"
    ],
    Misrouted: [
        "carNumber",
        "customerName",
        "deliveryNumber",
        "currentLocation",
        "shipDate",
        "route",
        "currentCarrier"
    ]
};

export const reportKeys: ExceptionTypeKeys[] = [
    "BadOrder",
    "Delayed",
    "OriginDestination",
    "LoadStatusMismatch",
    "EtaDelayFlag",
    "Misrouted"
];

export function generateExceptionWidgetData(
    carType: CarType,
    availableExceptions: ExceptionReport[],
    shipmentExceptions: Record<ExceptionShipmentType, number>
): ExceptionReportWidget[] {
    return availableExceptions.map((report) => {
        const { exceptionPath, key } = report;
        const reportKey = generateReportKey(carType, key);
        const reportUrl = generateReportUrl(carType, exceptionPath);

        return {
            ...report,
            count: shipmentExceptions[reportKey],
            reportUrl
        };
    });
}

export function generateExceptionReportBaseList(
    exceptionTypeKeys: ExceptionTypeKeys[]
): ExceptionReport[] {
    return exceptionTypeKeys.map((key) => {
        const exceptionTypePath = ExceptionTypePath[key];

        return createReportBase(key, exceptionTypePath);
    });
}

function createReportBase(
    key: ExceptionTypeKeys,
    exceptionTypePath: ExceptionTypePath
): ExceptionReport {
    return {
        columnInclusions: exceptionColumnInclusionMap[key],
        exceptionPath: exceptionTypePath,
        icon: iconMapper[key],
        key,
        reportName: ReportName[key],
        reportSubtitle: ReportSubtitle[key]
    } as ExceptionReport;
}

function generateReportKey(
    carType: CarType,
    key: ExceptionTypeKeys
): ExceptionShipmentType {
    const capitalizedCarType = capitalize(carType);

    return `${key}${capitalizedCarType}` as ExceptionShipmentType;
}

function generateReportUrl(carType: CarType, exceptionPath: ExceptionTypePath) {
    return `/exceptions/${carType}/${exceptionPath}` as ExceptionReportWidget["reportUrl"];
}

function capitalize(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1);
}
