import React, { ErrorInfo } from "react";
import { log, LogLevel } from "@cpchem/logging";

export function ErrorMessage(): JSX.Element {
    return <>An error has occurred. Please refresh the page.</>;
}

export function LogError(error: Error, errorInfo: ErrorInfo): void {
    log(
        `error: ${error.message};\nerrorInfo: ${errorInfo.componentStack}`,
        LogLevel.ERROR
    );
}
