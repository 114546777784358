import { AccountInformation } from "@cpchem/azure-auth";

export type UserTraits = {
    name: string;
    isGuest: boolean;
    company: string;
    email: string;
};

export function getUserTraits(userAccount: AccountInformation): UserTraits {
    const name = userAccount.name
        ? (userAccount.name as string)
        : userAccount.username;
    const company = userAccount.organizationalDetails?.company
        ? (userAccount?.organizationalDetails?.company as string)
        : userAccount.username;
    const email = userAccount.personalDetails?.email
        ? (userAccount.personalDetails?.email as string)
        : "unknown";
    return {
        name: name,
        isGuest: userAccount.isGuest,
        company: company,
        email: email
    };
}
