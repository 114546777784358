import React from "react";
import "../styles.scss";
import { SnoCard, ShipmentInfo, ContainerCard } from "@components";
import { Accordion, Card } from "@cpchem/covalence-ui";
import { faAbacus } from "@fortawesome/pro-light-svg-icons";
import { Container } from "../../../models/container/container";

export interface ContainerInformationProps {
    containers?: Container[];
}

export function ContainerInformation({
    containers
}: ContainerInformationProps): JSX.Element {
    if (containers === undefined || containers.length === 0) {
        return (
            <SnoCard
                className="container-information"
                icon={faAbacus}
                title="Container Information"
            >
                <>No Containers Found</>
            </SnoCard>
        );
    }

    const { materialAverageAmount, materialUnit } = containers[0];
    return (
        <SnoCard
            className="container-information"
            icon={faAbacus}
            title="Container Information"
        >
            <Accordion defaultOpen label="">
                <div className="container-metadata">
                    <ShipmentInfo
                        label="Number of Containers"
                        infoProps={[
                            {
                                value: containers.length
                            }
                        ]}
                    />
                    <ShipmentInfo
                        label="Order Weight"
                        infoProps={[
                            {
                                value: `${
                                    materialAverageAmount * containers.length
                                } ${materialUnit}`
                            }
                        ]}
                    />
                </div>
                <Card className="container-information-body">
                    {containers.map((container) => {
                        return (
                            <ContainerCard
                                key={container.containerNumber}
                                materialDescription={
                                    container.materialDescription
                                }
                                containerNumber={container.containerNumber}
                                material={container.material}
                                amount={container.materialAverageAmount}
                                unit={container.materialUnit}
                            />
                        );
                    })}
                </Card>
            </Accordion>
        </SnoCard>
    );
}
