import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchShipmentExceptionReportAsync,
    setAvailableExceptions
} from "@stores/Exceptions";
import { DispatchType, RootState } from "@stores";
import {
    CarType,
    CarTypeParam,
    generateExceptionReportBaseList,
    ExceptionShipmentType,
    ExceptionReportWidget,
    generateExceptionWidgetData,
    reportKeys
} from "@models/exceptions";
import { Personalization } from "@models";

interface HookReturn {
    isLoading: boolean;
    exceptions: ExceptionReportWidget[];
}

export function useShipmentExceptions(
    personalization: Personalization | null,
    activePersonalizationId: string | null
): HookReturn {
    let shipmentExceptions: Record<ExceptionShipmentType, number> | undefined;

    const { carType } = useParams<keyof CarTypeParam>() as CarTypeParam;
    const { availableExceptions, exceptionsCount, isLoading } = useSelector(
        (state: RootState) => state.exceptions
    );
    const dispatch = useDispatch<DispatchType>();

    if (exceptionsCount) {
        shipmentExceptions = exceptionsCount;
    }

    useEffect(() => {
        if (!availableExceptions) {
            const exceptionReportConfig =
                generateExceptionReportBaseList(reportKeys);
            dispatch(setAvailableExceptions(exceptionReportConfig));
        }
    }, [availableExceptions, dispatch]);

    useEffect(() => {
        // Don't fetch exceptions if personalization results haven't come back yet.
        if (!personalization) {
            return;
        }

        async function getShipmentExceptions(carType: CarType) {
            dispatch(
                fetchShipmentExceptionReportAsync({
                    carType: carType,
                    userId: activePersonalizationId
                })
            );
        }

        getShipmentExceptions(carType);
    }, [carType, personalization, activePersonalizationId, dispatch]);

    let exceptions: ExceptionReportWidget[] = [];

    if (availableExceptions && shipmentExceptions) {
        exceptions = generateExceptionWidgetData(
            carType,
            availableExceptions,
            shipmentExceptions
        ).filter(({ count }) => count !== 0);
    }

    return {
        isLoading,
        exceptions
    };
}
