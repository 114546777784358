import { Favorite } from "@models";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    PersonalizationApi,
    PersonalizationApiServiceKey,
    ServiceLocator
} from "@services";

interface FavoritesSliceState {
    favorites: Favorite[] | null;
    isFavoritesLoading: boolean;
}

const favoritesKey = "favorites";

export const getFavoritesAsync = createAsyncThunk(
    "favorites/getFavorites",
    async () => {
        const personalizationService = ServiceLocator.get<PersonalizationApi>(
            PersonalizationApiServiceKey
        );
        const getFavorites =
            await personalizationService.getPersonalizationByKeyAsync(
                favoritesKey
            );
        return getFavorites.data;
    }
);

const getFavoritesFromLocalStorage = () => {
    const stringifiedFavorites = localStorage.getItem(favoritesKey);
    return stringifiedFavorites ? JSON.parse(stringifiedFavorites) : [];
};

const updateFavoritesInLocalStorage = (favorites: Favorite[]) => {
    localStorage.setItem(favoritesKey, JSON.stringify(favorites));
};

const updateFavoritesWithApi = (favorites: Favorite[]) => {
    const personalizationService = ServiceLocator.get<PersonalizationApi>(
        PersonalizationApiServiceKey
    );

    personalizationService.patchPersonalizationByKeyAsync(
        favoritesKey,
        JSON.stringify(favorites)
    );
};
export const FavoritesSlice = createSlice({
    name: favoritesKey,
    initialState: {
        favorites: null,
        isFavoritesLoading: false
    } as FavoritesSliceState,
    reducers: {
        toggleFavorite(state, action) {
            // check if favorite is currently in array
            const favorites = state.favorites ?? [];
            const payloadAlreadyInFavorites = favorites.find(
                (favorite) => favorite.id === action.payload.id
            );
            if (payloadAlreadyInFavorites) {
                state.favorites = favorites.filter(
                    (favorite) => favorite.id !== action.payload.id
                );
            } else {
                state.favorites = [...favorites, action.payload];
            }
            updateFavoritesWithApi(state.favorites);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getFavoritesAsync.fulfilled, (state, { payload }) => {
            state.favorites = payload ? JSON.parse(payload.value) : [];

            // if favorites not found use local storage value this is the migration to api
            if (state.favorites?.length === 0) {
                const localFavorites = getFavoritesFromLocalStorage();

                // if favorites found from local storage update api for seamless transition
                if (localFavorites.length > 0) {
                    updateFavoritesWithApi(localFavorites);
                    state.favorites = localFavorites;
                }

                // clear out old favorites in local storage
                updateFavoritesInLocalStorage([]);
            }
            state.isFavoritesLoading = false;
        });

        builder.addCase(getFavoritesAsync.pending, (state) => {
            state.isFavoritesLoading = true;
        });
    }
});
export const { toggleFavorite } = FavoritesSlice.actions;
export default FavoritesSlice.reducer;
