import { ShipmentDetailsFavoriteView } from "@components/favorites/components/views/shipmentDetailsFavoriteView";

export interface Favorite {
    componentKey: FavoriteType; // this is used to reinstantiate the component along with its props
    componentProps: string;
    id: string;
    path: string;
}

export enum FavoriteType {
    ShipmentDetails = "shipment-details"
}

export const FavoriteMap = {
    ["shipment-details"]: ShipmentDetailsFavoriteView
};
