import React from "react";
import { Shipment } from "@models";

import { ActiveResultsGrid } from "../components/active-results-grid";
import { HistoricalResultsGrid } from "../components/historical-results-grid";

export type ResultsGridProps = {
    isHistorical: boolean;
    shipments: Shipment[];
};

export function ResultsGrid({
    isHistorical,
    shipments
}: ResultsGridProps): JSX.Element {
    return isHistorical ? (
        <HistoricalResultsGrid shipments={shipments} />
    ) : (
        <ActiveResultsGrid shipments={shipments} />
    );
}
