import { Component_Elements } from "@components/elements";
import React from "react";

import "./styles.scss";

export interface PageHeaderProps {
    /**
     * Optional isSubheader
     */
    isSubheader?: boolean;
    /**
     * Optional Badge
     */
    badge?: React.ReactNode;
    title: string;
    /** Optional Icon */
    icon?: React.ReactNode;
    /** Optional content, which will be right-aligned */
    children?: React.ReactNode;
    /**
     * Optional Subtitle
     */
    subtitle?: React.ReactNode;
}

export function PageHeader({
    badge,
    children,
    icon,
    isSubheader,
    subtitle,
    title
}: PageHeaderProps): JSX.Element {
    const className = isSubheader ? "page-header sub-header" : "page-header";

    return (
        <div className={className} data-testid={Component_Elements.page_header}>
            <h1>
                <div
                    className="title"
                    data-testid={Component_Elements.page_title}
                >
                    {icon && <span className="icon">{icon}</span>}
                    {title}
                    {badge && <span className="badge">{badge}</span>}
                </div>
            </h1>
            {subtitle && (
                <h4 data-testid={Component_Elements.page_subtitle}>
                    {subtitle}
                </h4>
            )}
            {children && (
                <div
                    data-testid={Component_Elements.page_header_children}
                    className="children"
                >
                    {children}
                </div>
            )}
        </div>
    );
}
