import { DataGrid } from "@cpchem/covalence-ui";
import { Shipment } from "@models";
import React from "react";
import { ResultRow } from "./result-row";
import { TrainIcon } from "../train-icon";
import { HistoricalResultRowData, SHIPDATE_ACCESSOR } from "../../models";
import { getFirstProduct } from "@utilities";

export type ResultsGridProps = {
    shipments: Shipment[];
};

const columns = [
    {
        Header: "Type",
        accessor: "icon",
        disableSortBy: true
    },
    {
        Header: "Transport #",
        accessor: "carNumber"
    },
    {
        Header: "Customer Name",
        accessor: "customerName",
        id: "customerName"
    },
    {
        Header: "Ship to #",
        accessor: "shipToNumber",
        id: "shipToNumber"
    },
    {
        Header: "Delivery #",
        accessor: "deliveryNumber",
        id: "deliveryNumber"
    },
    {
        Header: "PO #",
        accessor: "purchaseOrderNumber",
        id: "purchaseOrderNumber"
    },
    {
        Header: "Ship Date",
        accessor: SHIPDATE_ACCESSOR
    },
    {
        Header: "Product",
        accessor: "productName",
        id: "productName"
    }
];

export function HistoricalResultsGrid({
    shipments
}: ResultsGridProps): JSX.Element {
    const data = shipments.map(toGridRow);

    const gridProps = {
        data,
        columns,
        useStickyHeaders: true,
        rowRenderer: ResultRow
    };

    return (
        <div className="historical-results">
            <DataGrid {...gridProps} />
        </div>
    );
}

function toGridRow(shipment: Shipment): HistoricalResultRowData {
    const {
        deliveryNumber,
        carNumber,
        customerName,
        shipDate,
        productShipmentDetails,
        shipToNumber,
        destination,
        purchaseOrderNumber
    } = shipment;

    const { name: productName } = getFirstProduct(productShipmentDetails);

    return {
        deliveryNumber,
        carNumber,
        customerName,
        shipDate,
        productName,
        shipToNumber,
        destination,
        icon: <TrainIcon />,
        purchaseOrderNumber
    };
}
