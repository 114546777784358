import React from "react";
import { DataGridRow, Tooltip } from "@cpchem/covalence-ui";
import { ResultRowData } from "../../models";
import { parseAddress, toLocaleDateString } from "@utilities";
import { faCircleSmall } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function shipmentStatusCell(row: DataGridRow): JSX.Element {
    const { latestLocation, shipmentStatus, etaDelayFlag } =
        row.original as ResultRowData;
    const latestLocationText = parseAddress(latestLocation);
    if (!shipmentStatus?.status || !shipmentStatus?.date) {
        return <td />;
    }
    return (
        <td className="left">
            {`${shipmentStatus.status.toUpperCase()} - ${toLocaleDateString(
                shipmentStatus.date
            )}`}
            {etaDelayFlag === 1 && (
                <span className="eta-delay-flag-icon">
                    <Tooltip
                        content={"ETA pushed out within past 24 hours"}
                        side="right"
                    >
                        <FontAwesomeIcon icon={faCircleSmall} />
                    </Tooltip>
                </span>
            )}
            <small>{latestLocationText}</small>
        </td>
    );
}
